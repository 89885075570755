import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Select, Button, Modal, Spin, Table, Image, Input, DatePicker, Radio, Space, Tabs,Popover, message, InputNumber } from "antd";
import { LoadingOutlined, PlusCircleOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import AuditTrail from "../../../assets/images/auditTrail.svg";
import Arrow from "../../../assets/images/arrow.svg";
import { getSoProductData, getAddonProductData, getRoleBusinessUnit,getSalesOrderDetailsData } from "../../../services/custom";
import { getOrderTypeData, getNewSalesCustomerData, getNewSalesPrice, getNewSalesRep, getCustomerAddressData, getOrderFormData, getSalesTypeData } from "../../../services/generic";
import moment from "moment";
import { serverUrl, genericUrl } from "../../../constants/serverConfig";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import ListData from "../newSalesOrder/listData";
import "antd/dist/antd.css";
import "../../../styles/antd.css";
import Filter from "../../../assets/images/filter.svg";
// import { useHistory } from "react-router";
import { useParams } from "react-router-dom";


const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";

const NewSalesOrder = () => {
  const inputRef = React.useRef(null);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [headerform] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [metalForm] = Form.useForm();
  const [solitaireForm] = Form.useForm();

  // const [soOrderId, setSoOrderId] = useState("");
  const [colorValue, setColorValue] = useState(0);
  const [color, setColor] = useState("1");
  const [clarityValue, setClarityValue] = useState(0);
  const [clarity, setClarity] = useState("1");
  const [ringSizeValue, setRingSizeValue] = useState(0);
  const [ringSize, setRingSize] = useState("1");
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg1, setErrorMsg1] = useState(false);
  const [errorMsg2, setErrorMsg2] = useState(false);

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [sku, setSku] = useState("");
  const [description, setDescription] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [orderTypeId, setOrderTypeId] = useState("");
  const [customerId, setCustomerID] = useState("");
  const [customerAdd, setCustomerAdd] = useState("");
  const [schDelryDate, setSchDelryDate] = useState("");
  const [price, setPrice] = useState("");
  const [salesRep, setSalesRep] = useState("");
  const [productId, setProductId] = useState("");
  const [uomId, setUomId] = useState("");
  const [taxId, setTaxId] = useState("");
  const [priceValue, setPriceValue] = useState("1");
  const [salesTypeData, setSalesTypeData] = useState([]);
  const [orderFormData, setOrderFormData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [solitaireData, setSolitaireData] = useState([]);
  const [qtyValue, setQtyValue] = useState("1");

  const [bUnitId, setBUnitId] = useState("");
  const [value, setValue] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [detailsVisbile, setDetailsVisbile] = useState(false);
  const [isDisplayVisible, setIsDisplayVisible] = useState(false);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [salesRepData, setSalesRepData] = useState([]);
  const [address, setAddress] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [attributeData ,setAttributeData] = useState([])
  // const [metalValue, setMetalValue] = useState("");
  // const [metalPrice,setMetalPrice] = useState(false)
  // const [solitairePrice,setSolitairePrice] = useState(false)
  const [totalPrice,setTotalPrice] = useState('')
  const [isListView,setIsListView] = useState(false)
  // const [isTableVisible, setIsTableVisible] = useState(false);


  const [productDetails, setProductDetails] = useState([]);
  const [id, setId] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const { recordId } = useParams();


  useEffect(() => {
    const initialDate = moment(new Date()).format("YYYY-MM-DD");
    setSelectedDate(initialDate);
    setSchDelryDate(initialDate);
    getBusinessUnit();
    headerform.setFieldsValue({
      orderDate: moment(initialDate, dateFormat),
      schDeliveryDate: moment(initialDate, dateFormat),
    });
  }, []);

  useEffect(() => {
    if (recordId === "New_Record") {
      setIsListView(true);
    } else if (recordId) {
      setIsListView(true);
      setLoading(true);
      salesOrderDetailsData(recordId)
    } else {
      setIsListView(false);
    }
  }, [recordId]);

  

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "costPrice",
      key: "costPrice",
    },
  ];

  const dataColumns = [
    {
      title: "Shape",
      dataIndex: "DS_Shape",
      key: "DS_Shape",
      width: 80,
    },
    {
      title: "Carat",
      dataIndex: "DS_SIZE",
      key: "DS_SIZE",
      width: 80,
    },

    {
      title: "Color",
      dataIndex: "DS_Colour",
      key: "DS_Colour",
      width: 80,
    },
    {
      title: "Clarity",
      dataIndex: "DS_Clarity",
      key: "DS_Clarity",
      width: 80,
    },
    // {
    //   title: "Quantity",
    //   dataIndex: "reqQty",
    //   width: 80,
    //   render: (text, record, index) => <InputNumber size="small" style={{ width: "95%" }} min={0} max={1000000000} value={text} onChange={onInputChange("reqQty", index)} />,
    // },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 80,
    },
  ];
  const salesOrderDetailsData = async (recordId) => {
    const productResponse = await getSalesOrderDetailsData(recordId);
    setLoading(false);
    console.log(productResponse);
    headerform.setFieldsValue({
      orderType: productResponse[0].docTypeName,
      customer: productResponse[0].customerName,
      address: productResponse[0].address,
      priceList: productResponse[0].priceListName,
      salesRep: productResponse[0].salesRepName,
      orderFrom: productResponse[0].channelName,
      saleType: productResponse[0].saleTypeName,
      schDeliveryDate:moment(productResponse[0].dateordered),
      orderDate:moment(productResponse[0].datepromised)
      // designDescription: productResponse[0].description,
    });
    // if (productResponse[0].image === "undefined" || productResponse[0].image === null) {
    //   setImageVisible(false);
    //   setImgSrc('')
    // }else{
    //   setImageVisible(true);
    //   setImgSrc(productResponse[0].image);
    // }

    setOrderTypeId(productResponse[0].csDoctypeId)
    setCustomerID(productResponse[0].sCustomerId)
    setCustomerAdd(productResponse[0].sCustomerShippingId)
    setSchDelryDate(moment(productResponse[0].deliveryDate).format("DD-MM-YYYY"))
    setPrice(productResponse[0].sPricelistId)
    setSalesRep(productResponse[0].salesRepId)
    // setProductCategoryId(productResponse[0].productCategory);
    // setSketchRef(productResponse[0].sketchId);
    // setDesignedById(productResponse[0].designedBy);
    // setSubProductId(productResponse[0].subProduct);
    // setDesignId(productResponse[0].plmDesignId);
    // setDesignCode(productResponse[0].designcode);
    // setDesignName(productResponse[0].designName);
    // setBUnitId(productResponse[0].bunitId);
    // setProductId(productResponse[0].product);
    // if (productResponse[0].attributes.length > 0) {
    //   FormValuesData(productResponse[0].attributes);
    // }
  };


  const getDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const getSoProduct = async (searchKey) => {
    const soProductResponse = await getSoProductData(searchKey);
    // console.log("soProductResponse============>", soProductResponse);
    let filteredData1 = soProductResponse?.filter((values) => {
      return values.name?.toLowerCase().includes(value?.toLowerCase());
    });
    setFilteredData(filteredData1);
    setLoading(false);
  };

  const getAddonProduct = async (key) => {
    let addonProductResponse = await getAddonProductData(key);

    console.log("addonProductResponse=============>", addonProductResponse);
    setImgUrl(addonProductResponse[0]?.imageurl)
    setSku(addonProductResponse[0]?.designCode)
    setDescription(addonProductResponse[0]?.name)
    let metalData1 = []
    let solitaireData1 = []
    let attributeData1 = []
    addonProductResponse[0]?.designs.forEach((element)=>{
      if(element.groupName === "Metal"){
        metalData1.push(element)
      }else if(element.groupName === "Solitaire"){
        solitaireData1.push(element)
      }else{
        attributeData1.push(element)
      }
    })
    setAttributeData(attributeData1)
    setMetalData(metalData1)
    setSolitaireData(solitaireData1)
  };

  const getValues =()=>{
    const fieldValues = metalForm.getFieldsValue(true)
    // console.log('click',fieldValues)
    if(metalData.length === Object.keys(fieldValues).length || metalData.length === Object.keys(fieldValues).length-1){
    const arrayForMutation = []
    // setMetalPrice(true)
    let metPrice = {metal:'true'}
    let groupId
      for (let i = 0; i < metalData.length; i++) {
        Object.entries(fieldValues).map((item) => {
          let id = item[0].split("-")
          if (metalData[i].mAttributeId === id[1] && metalData[i].name !=="Metal_Weight (gm)") {
            groupId = metalData[i].mAttributeGroupId
            arrayForMutation.push(`{
              designAttributeId: "${id[1]}",
              value: "${item[1]}"
            }`);
          }
        });
      }
    getPriceData(groupId,arrayForMutation,metPrice)
    }
  }

const getSolitairePrice = ()=>{
    const solitaireValue = solitaireForm.getFieldsValue(true)
    if(solitaireData.length === Object.keys(solitaireValue).length || solitaireData.length === Object.keys(solitaireValue).length-1){
      // setSolitairePrice(true)
      let solitaPrice = {solitaire:'true'}
      const arrayForMutation = []
      let groupId
        for (let i = 0; i < solitaireData.length; i++) {
          Object.entries(solitaireValue).map((item) => {
          let id = item[0].split("-")
            if (solitaireData[i].mAttributeId === id[1]) {
              groupId = solitaireData[i].mAttributeGroupId
              arrayForMutation.push(`{
                designAttributeId: "${id[1]}",
                value: "${item[1]}"
              }`);
            }
          });
        }
      getPriceData(groupId,arrayForMutation,solitaPrice)
  
      }
  }

  const getPriceData = async(id,arrayForMutation,price)=>{
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
let key =Object.keys(price)
    const priceData = {
      query:`
      query{
        getAddOnProductPrice( design: {
            mAttributeGroupId: "${id}" 
        attributes:[${arrayForMutation}]})  
         {
           productPrice
           productId
        }  
      }
      `
    }
    Axios({
      url: serverUrl,
      method: "POST",
      data: priceData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then((response)=>{
if(key[0] === 'solitaire'){
  let metalPrice1 = response.data.data?.getAddOnProductPrice[0]?.productPrice
  let solitairePrice1 = response.data.data?.getAddOnProductPrice[0]?.productPrice
  solitaireForm.setFieldsValue({
    solitairePrices :solitairePrice1
  })
  detailsForm.setFieldsValue({
    totalPrice:metalPrice1
  })
  setTotalPrice(metalPrice1)
}else{
  let metalPrice2 = response.data.data?.getAddOnProductPrice[0]?.productPrice
  metalForm.setFieldsValue({
    metalPrice :metalPrice2
  })
  let totalPrice1 = parseInt(totalPrice) + parseInt(metalPrice2)
  detailsForm.setFieldsValue({
    totalPrice:totalPrice1
  })
}
    })
  }

  const getOrdertype = async () => {
    const orderTypeResponse = await getOrderTypeData();
    setOrderTypeData(orderTypeResponse.searchData);
  };
  const getCustomer = async () => {
    const customerDataResponse = await getNewSalesCustomerData();
    setCustomerData(customerDataResponse.searchData);
  };

  const getPrice = async () => {
    const priceDataResponse = await getNewSalesPrice();
    setPriceData(priceDataResponse.searchData);
  };
  const getOrderForm = async () => {
    const orderFromresponse = await getOrderFormData();

    setOrderFormData(orderFromresponse.searchData);
  };
  const getSalesTYpe = async () => {
    const response = await getSalesTypeData();

    setSalesTypeData(response.searchData);
  };
  const getSalesRep = async () => {
    const salesRepResponse = await getNewSalesRep();
    setSalesRepData(salesRepResponse.searchData);
  };

  const getCustomerAddress = async (value) => {
    const addressResponse = await getCustomerAddressData(value);

    let name = [];

    Object.entries(addressResponse).map(([key, value]) =>
      name.push({ Name: value.value ? value?.value[0]?.Name : null, RecordID: value.value ? value?.value[0]?.RecordID : null })
    );
    setAddress(addressResponse);
  };

  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
    setBUnitId(businessUnitResponse.defaultCsBunitId);
  };

  const onSelectOrderType = (e, data) => {
    console.log(e, data);
    setOrderTypeId(e);
  };
  const onCustomerData = (e, data) => {
    setCustomerID(e);
    getCustomerAddress(e);
  };

  const getCustomerAdd = (e) => {
    setCustomerAdd(e);
  };
  const onSelectDate = (date, dateString) => {
    setSchDelryDate(dateString);
  };
  const onSalesRep = (e) => {
    setSalesRep(e);
  };
  const selectOrderForm = (e) => {
    console.log(e);
  };
  const selectsalesType = (e) => {
    console.log(e);
  };

  const onpriceData = (e, data) => {
    setPrice(e);
  };

  const MetalPrice = (e)=>{
    console.log(e.target.value)
  }
  const SolitairePrice = (e)=>{
    console.log(e.target.value)
  }

  const cancel = () => {
    setValue("");
    setLoading(false);
    setModalVisible(false);
  detailsForm.resetFields()

  };

  const confirm = () => {
    setValue("");
    setModalVisible(false);
  detailsForm.resetFields()

  };

  const onOkay = () => {
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
    const formFieldsData = detailsForm.getFieldsValue(true);
    const metalFormData = metalForm.getFieldsValue(true)
    const solitaireFormData = solitaireForm.getFieldsValue(true)
    
    let index;
    let data = [];
    index = productDetails.findIndex((element) => {
      return element.id === id;
    });
    if (index >= 0) {
      for (let i = 0; i < productDetails.length; i++) {
        if (productDetails[i].id === id) {
          productDetails[i].sku = formFieldsData.sku;
          productDetails[i].price = formFieldsData.totalPrice;
          productDetails[i].ringSize = formFieldsData.ringSize;
          productDetails[i].metal = formFieldsData.metal;
          productDetails[i].qty = formFieldsData.qty;
          productDetails[i].solitaire = tableData;
          productDetails[i].solitaireData = solitaireFormData;
          productDetails[i].metalData = metalFormData;
        }
      }
    } else {
      let newObj = {
        sku: formFieldsData.sku,
        price: formFieldsData.totalPrice,
        ringSize: formFieldsData.ringSize,
        metal: formFieldsData.metal,
        qty: formFieldsData.qty,
        id: uniqueId,
        imgUrl: imgUrl,
        solitaire: tableData,
        solitaireData: solitaireFormData,
        metalData: metalFormData,
      };
      data.push(newObj);
    }
  
    setImgUrl("");
    let finalArry = [...productDetails, ...data];
    detailsForm.resetFields();
    setLoading(false);
    setProductDetails(finalArry);
    setDetailsVisbile(true);
    setModalVisible(false);
  };

  const modalView = (e) => {
    setLoading(true)
    getSoProduct(e.target.value);
    setValue(e.target.value);
    setModalVisible(true);
    setIsDisplayVisible(true);
    setDetailsVisbile(true);
  };


  const onChange = (e) => {
    setLoading(true);
    console.log(e.target.value)
    getSoProduct(e.target.value);
    setValue(e.target.value);
  };

  const selectColorValue = (value) => {
    setColorValue(parseInt(value));
    setColor(value);
  };
  const selectColor = (value) => {
    let int = parseInt(value);
    if (int > colorValue) {
      setErrorMsg1(false);
    } else {
      setErrorMsg1(true);
    }
    setColor(value);
  };

  const selectClarityValue = (value) => {
    setClarityValue(parseInt(value));
    setClarity(value);
  };
  const selectClarity = (value) => {
    let int = parseInt(value);
    if (int > clarityValue) {
      setErrorMsg2(true);
    } else {
      setErrorMsg2(true);
    }
    setClarity(value);
  };

  const selectRingSizeValue = (value) => {
    setRingSizeValue(parseInt(value));
    setRingSize(value);
  };
  const selectRingSize = (value) => {
    let int = parseInt(value);
    setRingSize(value);
    if (int > ringSizeValue) {
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
    }
  };

  const getFieldData = (data) => {
    setValue("");
    setUomId(data.uom.csUomId);

    setProductId(data.mProductId);
    detailsForm.resetFields(["ringSize", "metal", "qty"]);
    detailsForm.setFieldsValue({
      sku: data.name,
      price: data.productAddons[0]?.price,
      qty: priceValue,
    });
  };

  const editProduct = (text) => {
    setModalVisible(true);
    setDetailsVisbile(false);
    setId(text.id);
    // setTableData(text.solitaire);
    setImgUrl(text.imgUrl);
    // setMetalData(text.metalData);
    console.log(text)
    detailsForm.setFieldsValue({
      // sku: text.sku,
      totalPrice: text.price,
      ringSize: text.ringSize,
      qty: text.qty,
    });
  };
  // const onSelectProductData = (e, data) => {
  //   if (e.length <= 1) {
  //     setSelectedRowKeys(e);
  //     setSolitaireData(data);
  //   }
  // };

  // const rowSelection = {
  //   onChange: onSelectProductData,
  // };
  const onFinish = () => {
    // setLoading();
  };
  const getValue = (e) => {
    // setMetalValue(e.target.value);
  };

  const changePrice = (e) => {
    setPriceValue(e.target.value);
  };

  const deleteRow = (e) => {
    const dataSource = [...productDetails];
    let newData = [];
    dataSource.filter((item) => {
      if (item.id !== e.id) {
        newData.push(item);
      }
    });
    setProductDetails(newData);
  };
  const selectRow = (record) => {
    
        metalForm.resetFields()
        solitaireForm.resetFields()
        console.log('record',record);
        setTaxId(record.taxRate.csTaxID);
        getFieldData(record);
        getAddonProduct(record.mProductId);
        setDetailsVisbile(!detailsVisbile);
        setIsDisplayVisible(false);
     
  };
  useEffect(() => {
    inputRef.current?.focus({
      cursor: "end",
    });
  }, [modalView]);

  const sharedProps = {
    ref: inputRef,
  };

  const getQtyValue = (e) => {
    setQtyValue(e.target.value);
  };

  const changePage =()=>{
    history.push(`/others/window/7439`)
  }

 

  const getData = () => {
    // CustomSalesOrder()
    headerform.validateFields().then((values) => {
      const userData = JSON.parse(window.localStorage.getItem("userData"));
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const uniqueId = uuid().replace(/-/g, "").toUpperCase();
      setLoading(true);
      if (solitaireData.length || metalData.length > 0) {
        const createPoOrder = {
          query: `mutation {
            createCustomSalesOrder (sOrder : {
                cSBunitID:"${bUnitId}"
                createdby:"${userData.user_id}"
                sOrderID:"${uniqueId}"
                csDoctypeId:"${orderTypeId}"
                orderDate:"${selectedDate}" 
                sCustomerId:"${customerId}"
                sCustomerShippingId:"${customerAdd}"
                deliveryDate:"${schDelryDate}"
                sPricelistId:"${price}"
                description:"Good"
                salesRepId:${salesRep ? `"${salesRep}"` : null}
                cwcChannelId:null
                cwrSaleTypeId:null
                deliverynotes:"Good"
                orderreference:"30"
                nettotal:10
                grosstotal:20
                taxamt:10
            }) {
            id
            status
            message
            }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: createPoOrder,
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${newToken.access_token}`,
          },
        }).then((response) => {
          console.log("response==========", response);
          const Status = response.data.data.createCustomSalesOrder.status;
          const messageForSuccess = response.data.data.createCustomSalesOrder.message;
          if (Status === "200") {
            let recordId = response.data.data.createCustomSalesOrder.id;
            const solitaireForm1 = solitaireForm.getFieldsValue(true)
            const metalForm1 = metalForm.getFieldsValue(true)
            console.log("metalForm1", metalForm1)
            headerform.resetFields();
            let uniqueId = uuid().replace(/-/g, "").toUpperCase();
            const arrayForMutation = [];
           if (solitaireForm1) {
             let item1
            const solitaireString = []
            Object.entries(solitaireForm1).map((element)=>{
              let string = element[0].split("-")
              solitaireString.push(`${string[0]}:${element[1]}`)
            })
            console.log(solitaireString)

             item1 = solitaireString.toString()
              let uniqueId2 = uuid().replace(/-/g, "").toUpperCase();
              arrayForMutation.push(
                `{
                  sOrderLineAddonsId:"${uniqueId2}"
                  sOrderLineId:"${uniqueId}"
                  qty:"${qtyValue}"
                  name:"${item1}"
                  price:"${solitaireForm1.solitairePrices}"
              }`
              );
            }
            if (metalForm1) {
            const metalStings =[]
              Object.entries(metalForm1).map((element)=>{
                let string = element[0].split("-")
                metalStings.push(`${string[0]}:${element[1]}`)
              })
              const item = metalStings.toString()
              let uniqueId2 = uuid().replace(/-/g, "").toUpperCase();
                arrayForMutation.push(
                  `{
                    sOrderLineAddonsId:"${uniqueId2}"
                    sOrderLineId:"${uniqueId}"
                    name:"${item}"
                    qty:"${qtyValue}"
                    price:"${metalForm1.metalPrice}"
                }`
                );
            }
            console.log(arrayForMutation);
            const orderData = {
              query: `mutation {
                upsertSalesOrderLine (sOrderLine : {
                    cSBunitID:"${bUnitId}"  
                    createdby:"${userData.user_id}"  
                    sOrderId:"${recordId}" 
                    sOrderlineID:"${uniqueId}"
                    mProductId:"${productId}"
                    csUomId:"${uomId}" 
                    taxId:"${taxId}"  
                    line:1 
                    qty:${qtyValue}
                    unitprice:10
                    listprice:null
                    linenet:null
                    linetax:null
                    linegross:null
                  subProducts : [${arrayForMutation}]
                }) {
                status
                message
                }
              }
  
              `,
            };
            Axios({
              url: serverUrl,
              method: "POST",
              data: orderData,
              headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${newToken.access_token}`,
              },
            }).then((response) => {
              console.log("soOrderId", response);
              const Status = response.data.data.upsertSalesOrderLine.status;
              if (Status === "200") {
                const data1 = {
                  query: `mutation {
                        runProcess(recordId:"${recordId}", ad_tab_id:"270EED9D0E7F4C43B227FEDC44C5858F", ad_field_id:"A15626E911C0419A92CC6ADBEE4F9649",parameters:"{}")
                          {
              messageCode,
              title,
              message
              }
                      }
                      `,
                };
                Axios({
                  url: genericUrl,
                  method: "POST",
                  data: data1,
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${newToken.access_token}`,
                  },
                }).then((response) => {
                  message.success(response.data.data.runProcess.message);
                  history.push(`/window/7004/${recordId}`);
                  setMetalData([]);
                  setSolitaireData([]);
                  setProductDetails([]);
                  setLoading(false);
                });
              } else {
                message.error(messageForSuccess);
                setLoading(false);
              }
            });
          } else {
            message.error("getting error while creating lines");
            setLoading(false);
          }
        });
      } else {
        message.error("Please select products to proceed!");
        setLoading(false);
      }
    });
  };

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      {isListView ? <div>
      <Row>
        <Col span={12}>
          <h2 onClick={changePage} style={{ fontWeight: "500", fontSize: "12px", color: "#1648AA", marginBottom: "0px", marginTop: "1%",cursor:'pointer' }}>New Sales Order</h2>
        </Col>
        <Col span={11}>
          <span style={{ float: "right" }}>
            <Button onClick={getData} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
              Book
            </Button>
            <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
              More
              <DownOutlined style={{ fontSize: "10px" }} />
            </Button>
          </span>
        </Col>
      </Row>

      <Card style={{ marginBottom: "8px" }}>
        <Form layout="vertical" form={headerform} onFinish={onFinish} name="headerForm" style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="orderType"
                label="Order type"
                rules={[
                  {
                    required: true,
                    message: "Please select Order Type!",
                  },
                ]}
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onSelectOrderType} onFocus={getOrdertype} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {orderTypeData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderId" label="Order Id" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Input readOnly style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderDate" label="Order Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker onChange={getDateChange} style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <p />
            <br />
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="schDeliveryDate" label="Sch Delivery Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker onChange={onSelectDate} style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="customer"
                rules={[
                  {
                    required: true,
                    message: "Please select Customer!",
                  },
                ]}
                label="Customer"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onCustomerData} onFocus={getCustomer} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {customerData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please select Address!",
                  },
                ]}
                label="Address"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={getCustomerAdd} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {Object.entries(address).map(([key, value]) => (
                    <Option
                      key={value.value ? value?.value[0]?.RecordID : null}
                      value={value.value ? value?.value[0]?.RecordID : null}
                      title={value.value ? value?.value[0]?.Name : null}
                    >
                      {value.value ? value?.value[0]?.Name : null}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <p />
            <br />
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item
                name="priceList"
                rules={[
                  {
                    required: true,
                    message: "Please select Price List!",
                  },
                ]}
                label="Price List"
                style={{ color: "#8E8E8E", fontSize: "13px" }}
              >
                <Select onSelect={onpriceData} onFocus={getPrice} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {priceData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="salesRep" label="Sales Rep" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={onSalesRep} onFocus={getSalesRep} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {salesRepData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="orderFrom" label="Order From" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={selectOrderForm} onFocus={getOrderForm} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {orderFormData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <p />
          <br />
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="saleType" label="Sale Type" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <Select onSelect={selectsalesType} onFocus={getSalesTYpe} style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }}>
                  {salesTypeData.map((data, index) => (
                    <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                      {data.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
        </Form>
      </Card>
      <Card style={{ marginBottom: "8px", height: "47px" }}>
        <Row>
          <Col className="gutter-row" span={12}>
            <span>
              <Input autoComplete={'off'} placeholder="Search" prefix={<SearchOutlined />} onChange={modalView} name="modal" value={value} />
            </span>
          </Col>
        </Row>
      </Card>
      {productDetails.map((product, ind) => (
        <div style={{ marginBottom: "8px" }}>
          <Card bodyStyle={{ height: "135px" }}>
            <Row gutter={16}>
              <Col className="gutter-row" span={3}>
                <Image src={product.imgUrl} style={{ height: "91px", width: "91px" }} />
              </Col>
              <Col className="gutter-row" span={6}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Design</h1>
              </Col>
              <Col className="gutter-row" span={3}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Qty</h1>
              </Col>
              <Col className="gutter-row" span={3}>
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Unit Price</h1>
              </Col>
              <Col className="gutter-row" span={6}></Col>

              <Col className="gutter-row" span={3}>
                <span style={{ float: "right" }}>
                  <EditOutlined style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} onClick={() => editProduct(product)} />
                  <DeleteOutlined onClick={() => deleteRow(product)} style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} />
                </span>
              </Col>
            </Row>
            <div
              style={{
                marginTop: "-74px",
              }}
            >
              <Row gutter={16}>
                <Col className="gutter-row" span={3}></Col>
                <Col className="gutter-row" span={6}>
                  <h1 style={{ fontSize: "13px" }}>{product.sku}</h1>
                </Col>
                <Col className="gutter-row" span={3}>
                  <h1 style={{ fontSize: "13px" }}>{product.qty}</h1>
                </Col>
                <Col className="gutter-row" span={3}>
                  <h1 style={{ fontSize: "13px" }}>{product.price}</h1>
                </Col>
                <Col className="gutter-row" span={9}></Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={3}></Col>
            {Object.entries(product.metalData).map((element=>{
              if(element !== undefined || element !== null){
                let name = element[0].split("-")
                return(
                  <Col span={3} style={{marginTop:'12px'}}>
                    { name[0]}
                    <br/>
                    {element[1]}	
                  </Col>)	
              }
            }))}	
           {Object.entries(product.solitaireData).map((element=>{
              if(element !== undefined || element !== null){
                let name = element[0].split("-")
                return(
                  <Col span={3} style={{marginTop:'12px'}}>
                    { name[0]}
                    <br/>
                    {element[1]}	
                  </Col>)	
              }
            }))}
              </Row>	  
            </div>
          </Card>
        </div>
      ))}

      <Modal width="85%" centered visible={modalVisible} closable={true} okText="Save" onOk={!detailsVisbile ? onOkay : confirm} onCancel={cancel}>
        {isDisplayVisible ? (
          <Card style={{ height: "65vh" }}>


            <Input name="modal" {...sharedProps} value={value} onChange={onChange} suffix={<Popover placement="bottom" content={(<>
              <div style={{cursor:'pointer'}}>
                one
              </div>
              <div style={{cursor:'pointer'}}>
                two
              </div>
            </>)} trigger="click">
  <img src={Filter} style={{cursor:'pointer'}}/>
  </Popover> }/>
            <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
              {/* {filteredData.length > 0 ? (
                <Table size="small" columns={columns} onRow={selectRow} dataSource={filteredData} pagination={false} style={{ height: "44vh", overflow: "scroll" }} />
              ) : null} */}
              <Row style={{height: "58vh", overflow: "scroll",overflowX:"hidden",paddingTop:'15px' }} >
              {filteredData.length > 0 ? (
                filteredData.map((item)=>(
                  <Col span={4} onClick={()=>selectRow(item)} style={{cursor:'pointer',borderWidth:'3px',borderColor:'#5D5454'}}>
                    <div>
                   <img src={item.imageurl} height="120px" width="120px"/>
                     </div>
                    <span style={{color: "#5D5454", fontSize: "13px",fontWeight:'550'}}>
                    {item.name}
                   </span>
                  </Col>
              ))
              ) : null}
              </Row>

            </Spin>
          </Card>
        ) : (
          ""
        )}

        {!detailsVisbile ? (
          <div>
            <Row>
              <Col span={8}>
                <img src={imgUrl} style={{ marginLeft: "8px", width: "350px", height: "350px" }} />
              </Col>
              <Col span={16} style={{ marginTop: "-5px", marginLeft: "-1px" }}>
                <span style={{ marginLeft: "16px", fontSize: "22px", font: "normal normal 600 22px/30px  Open Sans", color: "#666666" }}>{`${sku} ${description === null ? '': `-${description}` }`}</span>
                <Form form={detailsForm} name="detailsForm" layout="vertical" style={{ marginTop: "20px", marginLeft: "16px" }}>

                  <Row gutter={16}>
                    {attributeData.map((element)=>{
                           let data = element.value.split(',')
                                return (
                                <Col className="gutter-row" span={4} >
                                  <Form.Item  name={element.name} label={element.name} style={{ color: "#8E8E8E", fontSize: "13px" }}>
                                    <Select showSearch style={{ width: "100%",marginBottom: "24px"}}>
                                    {data?.map((option, index) => (
                                    <Option key={element.lineAttributeId} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                                  </Form.Item>
                                  </Col>)
                          })}
                    <Col className="gutter-row" span={3} style={{ height: "58px" }}>
                      <Form.Item name="qty" label="Qty" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input onChange={getQtyValue} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4} style={{ height: "58px" }}>
                      <Form.Item name="totalPrice" label="Price" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input onChange={changePrice} />
                      </Form.Item>
                    </Col>
                  </Row>
                  </Form>
                  <br />
                  <Tabs style={{ marginTop: "-6px" }}>
                  <TabPane tab="Solitaire" key="2" style={{ height: "170px" }}>
                      <Form name="solitaireForm" layout="vertical" form={solitaireForm} style={{ paddingLeft: "3px", paddingRight: "3px", paddingBottom: "3px" }}>
                        <Row gutter={16} style={{ display: "flex", alignItems: "center", height: "50px",marginLeft:'1px' }}>
                          {solitaireData.map((element)=>{
                            let data = element.value.split(',')
                            return (
                              <Col className="gutter-row" span={5}>
                            <Form.Item  name={`${element.name}-${element.mAttributeId}`} label={element.name} style={{ color: "#8E8E8E", fontSize: "13px" }}>
                            <Select showSearch style={{ width: "100%", marginBottom: "0px" }} onChange={getSolitairePrice}>
                            {data?.map((option, index) => (
                                    <Option key={element.lineAttributeId} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                            </Select>
                            </Form.Item>
                            </Col>)
                          })}
                        </Row>
                        <p />
                        <br />
                        <Row gutter={16} style={{ display: "flex", alignItems: "center", height: "50px", marginTop: "-10px",marginLeft:'2px' }}>
                        <Col className="gutter-row" span={4} >
                            <Form.Item name="colour" label="Colour" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                              <Select defaultValue={"1"} onSelect={selectColorValue}>
                                <Option key={"1"} value={"1"}>
                                  D
                                </Option>
                                <Option key={"2"} value={"2"}>
                                  E
                                </Option>
                                <Option key={"3"} value={"3"}>
                                  F
                                </Option>
                                <Option key={"4"} value={"4"}>
                                  G
                                </Option>
                                <Option key={"5"} value={"5"}>
                                  H
                                </Option>
                                <Option key={"6"} value={"6"}>
                                  I
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "28px" }}>
                            <span style={{ color: "#5D5454", fontSize: "13px",paddingTop:'2px' }}>to</span>
                          </Col>
                          <Col className="gutter-row" span={4} style={{ marginBottom: "-47px" }}>
                            <Form.Item validateStatus={errorMsg1 ? "error" : ""} help={errorMsg1 ? "select higher value" : ""} style={{ color: "#8E8E8E", fontSize: "13px" }}>
                              <Select value={color} onSelect={selectColor}>
                                <Option key={"1"} value={"1"}>
                                  D
                                </Option>
                                <Option key={"2"} value={"2"}>
                                  E
                                </Option>
                                <Option key={"3"} value={"3"}>
                                  F
                                </Option>
                                <Option key={"4"} value={"4"}>
                                  G
                                </Option>
                                <Option key={"5"} value={"5"}>
                                  H
                                </Option>
                                <Option key={"6"} value={"6"}>
                                  I
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={4}>
                            <Form.Item name="clarity" label="Clarity" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                              <Select defaultValue={"1"} onSelect={selectClarityValue}>
                                <Option key={"1"} value={"1"}>
                                  IF
                                </Option>
                                <Option key={"2"} value={"2"}>
                                  VVS1
                                </Option>
                                <Option key={"3"} value={"3"}>
                                  VVS2
                                </Option>
                                <Option key={"4"} value={"4"}>
                                  VS1
                                </Option>
                                <Option key={"5"} value={"5"}>
                                  VS2
                                </Option>
                                <Option key={"6"} value={"6"}>
                                  SI1
                                </Option>
                                <Option key={"7"} value={"7"}>
                                  SI2
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "28px" }}>
                            <span style={{ color: "#5D5454", fontSize: "13px" }}>to</span>
                          </Col>
                          <Col className="gutter-row" span={4} style={{ marginBottom: "-44px" }}>
                            <Form.Item validateStatus={errorMsg2 ? "error" : ""} help={errorMsg2 ? "select higher value" : ""} style={{ color: "#8E8E8E", fontSize: "13px" }}>
                              <Select value={clarity} onSelect={selectClarity}>
                                <Option key={"1"} value={"1"}>
                                  IF
                                </Option>
                                <Option key={"2"} value={"2"}>
                                  VVS1
                                </Option>
                                <Option key={"3"} value={"3"}>
                                  VVS2
                                </Option>
                                <Option key={"4"} value={"4"}>
                                  VS1
                                </Option>
                                <Option key={"5"} value={"5"}>
                                  VS2
                                </Option>
                                <Option key={"6"} value={"6"}>
                                  SI1
                                </Option>
                                <Option key={"7"} value={"7"}>
                                  SI2
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={4}>
                      <Form.Item name="solitairePrices" label="Solitaire Price" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                        <Input onChange={SolitairePrice} />
                      </Form.Item>
                    </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tab="Metal" key="1" style={{ height: "170px" }}>
                    <Form form={metalForm} name="metalForm" layout="vertical" >
                      <Row gutter={16} style={{marginLeft:'1px'}}>
                          {metalData.map((element)=>{
                           let data = element.value.split(',')
                                return (
                                <Col className="gutter-row" span={6} >
                                  <Form.Item  name={`${element.name}-${element.mAttributeId}`} label={element.name} style={{ color: "#8E8E8E", fontSize: "13px" }}>
                                    <Select showSearch style={{ width: "100%",marginBottom: "24px"}} onChange={getValues} >
                                    {data?.map((option, index) => (
                                    <Option key={element.lineAttributeId} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                                  </Form.Item>
                                  </Col>)
                          })}
                      </Row>
                      <Row>
                      <Col className="gutter-row" span={4} style={{ height: "58px" }}>
                      <Form.Item name="metalPrice" label="Metal Price" style={{ color: "#8E8E8E", fontSize: "13px",marginLeft:'10px' }}>
                        <Input onChange={MetalPrice}/>
                      </Form.Item>
                    </Col>
                      </Row>
                </Form>
                    </TabPane>
                    <TabPane tab="Side Diamonds" key="3">
                      Content of Tab Pane 3
                    </TabPane>
                  </Tabs>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </Modal>
      </div> :  	
        <ListData />
      }
    </Spin>
  );
};

export default NewSalesOrder;
