import React, { useState, useEffect } from 'react'
import "antd/dist/antd.css";
import { Row, Col, Card, Form, Checkbox,Image,Spin,Button } from "antd";
import { useGlobalContext } from "../../../lib/storage";
import {getPjDetailsData} from "../../../services/custom" 
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";



const CustomWindowHeader=()=> {
  const history = useHistory();
    const { globalStore } = useGlobalContext();
    const Themes = globalStore.userData.CW360_V2_UI;  
    const [headerFormData, setHeaderFormData] = useState(); 

    const [loading, setLoading] = useState(false); 
  

  
    const [outlettwofroms,setsutlettwofroms] = useState([]) 
    const [outletValue,setoutletValue] = useState([])
    const [dvnclass,setdvnclass]=useState([]) 
    const [dvnbankregulatory,setDVNBankRegulatory]=useState([])
    const [dvnAddress,setdvnAddress]=useState([]) 
    const [dvnCustomerKyc,setdvnCustomerKyc]=useState([])

    const [form] = Form.useForm(); 


    useEffect(() => {
      getData()
    }, [])

    const getData = async() =>{ 
      setLoading(true);
      const pjMasterId = localStorage.getItem("pjMasterId"); 
     
        const getDataResponse = await getPjDetailsData(pjMasterId); 
        if(getDataResponse !== undefined) {
          if(getDataResponse.length>0){ 
            // console.log("getDataResponse================>",getDataResponse)
            // console.log("getDataResponse[0].dvnContact==================>",getDataResponse[0])
          setHeaderFormData(getDataResponse) 
          setoutletValue(getDataResponse[0].dvnContact)
          setdvnclass(getDataResponse[0].dvnclassification) 
          setDVNBankRegulatory(getDataResponse[0].dvnBankDetails)
          setdvnAddress(getDataResponse[0].dvncustAddress)
          setdvnCustomerKyc(getDataResponse[0].dvnCustomer) 

        
          console.log(getDataResponse) 
          setsutlettwofroms(getDataResponse)
    
          for (let index = 0; index < getDataResponse.length; index++) {
            getDataResponse[index].key = getDataResponse[index].pjMasterId;
          } 
        
      
        }
        }
         
      setLoading(false);
    } 
    
  
   
    return (
      <div>
        
   
        <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
          <Card style={Themes.contentWindow.recordWindow.RecordHeader.headerCard}>
         <Form layout="vertical" name="control-hooks" form={form}>
         <Row>
         <Col span={24}>
         <span style={{float:'right'}}>
         <Button
              type="default"
              onClick={() => {
                history.push(`/others/window/7452`);
              }}
              style={{height:'2rem',width:'5.4rem',backgroundColor:'rgb(8 158 164)',border:'0.25px solid rgb(7, 136, 141)',borderRadius:'2px',fontSize:'14px',color:'rgb(255, 255, 255)',fontWeight:'500',fontFamily:'Open Sans',opacity:'1'}} 
              
            
            >
            Back
            </Button>
        </span>
        </Col>
        </Row>
         <Row gutter={16}>
           <Col span={6} >
                    <Form.Item label="Business Unit*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}}>
                      <span>{headerFormData!== undefined ? headerFormData[0].bUnitName:''}</span>
                    </Form.Item>        
         </Col> 
         <Col span={6}>
                <span>
                    <Form.Item label="PJ Code*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}}>
                      <span>{headerFormData!== undefined ? headerFormData[0].pjCode:''}</span>
                    </Form.Item>
                </span>
          </Col>
          <Col span={6}>
                <span>
                
                    <Form.Item label="PJ Name*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{headerFormData!== undefined ? headerFormData[0].pjName:''}</span>
                    </Form.Item>
                 
                </span>
              </Col>   
              <Col span={6}>
                <span>                
                    <Form.Item label="Customer Category" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{headerFormData!== undefined ? headerFormData[0].customerCategory.name : ''}</span>
                    </Form.Item>
                </span>
              </Col>      
         </Row>
         <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
              <Col span={6}>
                    <Form.Item label="GST No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{headerFormData!== undefined ? headerFormData[0].gstNo:''}</span>
                    </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Currency" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{headerFormData!== undefined ? headerFormData[0].currency.isoCode:''}</span>
                  </Form.Item>
            </Col> 
            <Col span={6}>
                  <Form.Item label="Nick Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? headerFormData[0].pjName:''}</span>
                  </Form.Item>
            </Col> 
            <Col span={6}>
                  <Form.Item label="Invoicing Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{headerFormData!== undefined ? headerFormData[0].pjName:''}</span>
                  </Form.Item>             
            </Col> 
          </Row>
          <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
              <Col span={6}>
                <Form.Item label="Invoicing(Y/N)" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                <Checkbox
                    checked={headerFormData!== undefined ? headerFormData[0].displayDone==="Y"?true:false:""}
                    

                  >
                
                  </Checkbox>
                </Form.Item>
        
              </Col> 
            <Col span={6}>
            <span>
            <Form.Item label="Invoicing Address"  style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
             <span>{headerFormData!== undefined ? headerFormData[0].invoicingAddress:''}</span> 
           </Form.Item> 
              
           </span>
            </Col> 
            <Col span={6}>
                  <Form.Item label="CSA Limit" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? headerFormData[0].csaLimit:''}</span>
                  </Form.Item>
            </Col> 
            <Col span={6}>
                  <Form.Item label="ASS Limit" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? headerFormData[0].asslimit:''}</span>
                  </Form.Item>
            </Col> 
          </Row> 
          <p style={{ marginBottom: "20px" }} />
          <Row gutter={16}>  
              <Col span={6}>
                <Form.Item label="ASS Start Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                <span>{headerFormData!== undefined ? moment(headerFormData[0].assStartDate).format("YYYY-MM-DD"):''}</span>
                </Form.Item>
              </Col> 
               <Col span={6}>
                <Form.Item  label="ASS End Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                <span>{headerFormData!== undefined ? moment(headerFormData[0].assEndDate).format("YYYY-MM-DD"):''}</span>
              </Form.Item> 
              </Col> 
              <Col span={6}>
                  <Form.Item label="Total Consignment Stock (TCS)" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{headerFormData!== undefined  ? (headerFormData[0].dvnCustomer!==undefined && headerFormData[0].dvnCustomer.length>0
                   ?(headerFormData[0].dvnCustomer[0].totalConsignmentStock):'') :''}</span>
                  </Form.Item>
            </Col> 
            <Col span={6}>
                  <Form.Item label="Outright Stock (OS)" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{headerFormData!== undefined ? (headerFormData[0].dvnCustomer!==undefined  && headerFormData[0].dvnCustomer.length>0 ?(headerFormData[0].dvnCustomer[0].outRightStock):''):''}</span>
                    </Form.Item>
            </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Total  Stock" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? (headerFormData[0].dvnCustomer!==undefined && headerFormData[0].dvnCustomer.length>0 ?(headerFormData[0].dvnCustomer[0].totalStock):''):''}</span>

                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Payment Terms" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? headerFormData[0].paymentTerms.name:''}</span>
                  </Form.Item>
                 </Col>
              </Row> 
              <p style={{ marginBottom: "20px" }} />
              <h4>More Information</h4>
              <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="PJ Type" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].pjtype:null}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="PJ Group" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{headerFormData!== undefined ? headerFormData[0].pjGroup:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="PJ Closure Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{headerFormData!== undefined ? moment(headerFormData[0].pjClosureDate).format("YYYY-MM-DD"):''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="PJ Onboarding Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{headerFormData!== undefined ? moment(headerFormData[0].pjOnboardingDate).format("YYYY-MM-DD"):''}</span>
                  </Form.Item>
                 </Col>
              </Row>
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>
             
                 <Col span={6}>
                 <Form.Item label="City" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].city:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="State" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{headerFormData!== undefined ? headerFormData[0].region.name:''}</span>

                  </Form.Item>
                 </Col>
              </Row>
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Zone" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].zone:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Email" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].email:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Mobile No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].mobileNo:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Country" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].country.name:''}</span>
                  </Form.Item>
                 </Col>
              </Row>
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Pincode" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].pincode:''}</span>
                  </Form.Item>
                 </Col> 
                <Row/> 
                 <Col span={6}>
                 <Form.Item label="WebSite Address" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].websiteAddress:''}</span>
                  </Form.Item>
                 </Col>
                
               </Row>
                 <p/>
                 <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Company Logo" name="companyLogo">
                 <Image
                 width={100}
                   src={headerFormData!== undefined ? headerFormData[0].companyLogo:''}       />
                  </Form.Item>
                 </Col>
                 
                 <Col span={6}>
                 <Form.Item label="Owner Pic" name="ownerPic">
                 <Image
                  width={100}
                  src={headerFormData!== undefined ? headerFormData[0].ownerPic:''}                 />
                  </Form.Item>
                 </Col>
                 </Row>
              
              <p style={{ marginBottom: "20px" }} />
             <h4>PJ Sales Distribution In(%)</h4>
             <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Solitaire Jewellery" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].solitaireJewellery:''}</span>

                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Small Diamond Jewellery" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].smallDiamondJewellery:''}</span>

                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Gold Jewellery" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].goldJewellery:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Luxury & Lifestyle Items" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].luxuryLifestyle:''}</span>
                  </Form.Item>
                 </Col>
              </Row>
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>
                 <Col span={6}>
                 <Form.Item label="Others" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].others:''}</span>
                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Registered With DS" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].registeredWithDs:''}</span>

                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Un-Registered With DS" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].unregisteredWithDs:''}</span>

                  </Form.Item>
                 </Col>
                 <Col span={6}>
                 <Form.Item label="Invoicing Format" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                 <span>{headerFormData!== undefined ? headerFormData[0].csDocType.name:''}</span>
                  </Form.Item>
                 </Col>
              </Row>
              <p style={{ marginBottom: "20px" }} />

              <hr/>
              <h3>KYC</h3>
              
              {dvnCustomerKyc.map((data)=>(
              <div>
              <Row gutter={16}>  
              <Col span={6}>
                    <Form.Item label="PJ Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.pjName}</span>
                    </Form.Item>
              </Col> 
             
            <Col span={6}>
              
              <span>
                  <Form.Item label="Nick Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}}>
                    <span>{data.nickname}</span>
                  </Form.Item>
               </span>
            </Col> 
            <Col span={6}>
                  <Form.Item label="Invoicing Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.invoicingName}</span>
                  </Form.Item>
            </Col> 
            <Col span={6}>
            <Form.Item  label="PJ Onboarding Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
            
              <span>{moment(data.pjOnboardingDate).format("YYYY-MM-DD")}</span>
           </Form.Item> 
            </Col> 

              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
          
                <Col span={6}>
                      <Form.Item label="PJ Closure Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{moment(data.pjClosureDate).format("YYYY-MM-DD")}</span>
                      </Form.Item>
                </Col> 
              <Col span={6}>
                    <Form.Item label="PJ Sales Rep" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{data.salesRep.name}</span>
                    </Form.Item>
              </Col>
              <Col span={6}>
                  <Form.Item label="Sales Rep Start Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{moment(data.pjSalesRepStartDate).format("YYYY-MM-DD")}</span>
                  </Form.Item>
              </Col>  
              <Col span={6}>
                  <Form.Item label="Sales Rep End Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    <span>{moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")}</span>
                  </Form.Item>
              </Col> 

              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
              <Col span={6}>
                  <Form.Item label="Owner Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{data.ownerName}</span>
                  </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Mobile No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                   <span>{data.mobileNo}</span>
                  </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Email" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                   <span>{data.email}</span>
                 </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Birth Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  <span>{data.birthDate}</span>
                  </Form.Item>
              </Col> 

            </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                
              <Col span={6}>
                  <Form.Item label="Anniversary Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                   <span>{data.anniversaryDate}</span>
                  </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Address" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                  </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="Owner Pic" name="ownerpic">
                   <Image 
                   width={100}
                     src={data.outletPic}     />
                  </Form.Item>
              </Col> 
              <Col span={6}>
                  <Form.Item label="SOR">
                  <Checkbox
                     checked={data.sor==="Y"?true:false}
                    
                  >
          
                  </Checkbox>
                  </Form.Item>
              </Col> 

            </Row>  
              <p style={{ marginBottom: "20px" }} /> 
              <Row gutter={16}>  
             
              <Col span={6}>
              <Form.Item label="KYC">
                  <Checkbox
                    checked={data.kyc==="Y"?true:false}
                  >
                  </Checkbox>
                </Form.Item>
              </Col> 

          
              <Col span={6}>
                  <Form.Item label="Website Address" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.websiteAddress}</span>
                  </Form.Item>
              </Col> 
            
              <Col span={6}>
                  <Form.Item label="Company Logo" name="companyLogo">
                     <Image
                     width={100}
                       src={data.compmanyLogo}/>
                  </Form.Item>
              </Col>               
            </Row>  
            <p style={{ marginBottom: "20px" }} /> 
            <hr/>
            </div>
              ))}
               <h3>Terms & Conditions</h3>
               {dvnAddress.map((data)=>(
               <div>
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Payment Terms" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.paymenttermName}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Margin" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.margin}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item label="Deposit Commited" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.depositCommited}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                        <Form.Item label="Credit Limit" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.pjCreditLimit}</span>
                        </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="CreditPeriod" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.paymenttermName}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}>
                      <Form.Item  label="Projection Jeweller Wise Target" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.projectionJwellerWiseTarget}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                        <Form.Item label="Store wise target" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                         <span>{data.storeWiseTarget}</span>
                        </Form.Item>
                  </Col>
                  <Col span={6}>
                      <Form.Item label="store wise prompt payment" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >                      {/* <span>{headerFormData!== undefined ? (headerFormData[0].dvncustAddress[0]!==undefined && headerFormData[0].dvncustAddress.length>0 ?(headerFormData[0].dvncustAddress[0].storeWisePromptPayment):'') :''}</span> */}
                         <span>{data.storeWisePromptPayment}</span>
                      </Form.Item>
                    </Col>  
                 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
              
                  <Col span={6}> 
                     <Form.Item label="Agreement Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{headerFormData!== undefined ? (headerFormData[0].dvncustAddress[0]!==undefined && headerFormData[0].dvncustAddress.length>0 ?moment((headerFormData[0].dvncustAddress[0].agreementDate)).format("YYYY-MM-DD"):'') :''}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Agreement Sign" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                    
                  <Checkbox
                    checked={data.agreementSign==="Y"?true:false}
                  
                    style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                   
                  >
                   
                  </Checkbox>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <hr/>
              </div>
               ))}
              <h3>Regulatory</h3>
              {dvnbankregulatory.map((data)=>(
              <div>
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Bank Account No*" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.bankAccNumber}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Bank Name*" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                     <span>{data.bankname}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Branch" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.branch}</span>

                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="IFSC Code" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.ifscCode}</span>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="GST No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.gstNo}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="PAN No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.panNo}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="TDS Applicable" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                       checked={data.tdsApplicable==="Y"?true:false}
                
                  >
                
                  </Checkbox>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="GST Applicable" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                       checked={data.gstApplicable==="Y"?true:false}
                       
                  >
                   
                  </Checkbox>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                  <Col span={6}>
                      <Form.Item  label="Incentive Prompt Payment" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                        checked={data.incentivePromptPayment==="Y"?true:false}
                      
                  >
                  
                  </Checkbox>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Jeweller Level Prompt Payment" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                        checked={data.jwellerlevelPromtPayment==="Y"?true:false}
                       
                  >
                
                  </Checkbox>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
               <hr/>
              </div> 
              ))}
            
              <h3>Stock Mix</h3>
              {dvnclass.map((data)=>(
              <div>
            
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Small%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.small}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Medium %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.medium}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Large %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.large}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Ex.Large %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.exLarge}</span>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="DEF VVS %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.defVvs}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="DEF VS%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.defVs}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="DEF SI%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.defSi}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="GH VVS%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.ghVvs}</span>
                      </Form.Item>
                  </Col> 
              </Row> 
              <p style={{ marginBottom: "20px" }} />
            
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="GH VS%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.ghVs}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="GH SI%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.ghSi}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="IJK VVS%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{data.ijkVvs}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="IJK VS%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.ijkVs}</span>
                      </Form.Item>
                  </Col> 
              </Row>   
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="IJK SI%*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.ijksi}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="DSD %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.dsd}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="DSJ %*" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.dsj}</span>
                      </Form.Item>
                  </Col> 
              </Row>   
              <p style={{ marginBottom: "20px" }} />
              <hr/> 
              </div>
              ))}
              <h3>OutLet</h3>
                {outletValue.map((data)=>(
                 <div>
                   <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Outlet Name" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                         <span>{data.outletName}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Nick  Name" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                     <span>{data.nickName}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="outletaddress" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                          <span>{data.outletFullAddress}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="City" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                         <span>{data.outletCity}</span>
                      </Form.Item>
                  </Col> 
              </Row>   
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Pin" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.pinCode}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="State" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                     <span>{data.stateName}</span>
                    </Form.Item> 
                  </Col> 
                  <p/>
                  <Col span={6}>
                      <Form.Item  label="Zone" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.zone}</span>
                      </Form.Item>
                  </Col> 
                 
                  <Col span={6}>
                      <Form.Item  label="Tier" style={{color:"#080707",fontWeight:'300',fontSize:'15px'}} >
                      <span>{data.tier}</span>
                      </Form.Item>
                  </Col> 
                </Row>   
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Phone No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      
                      <span>{data.mobileNo}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Area" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.area}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Email" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.email}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Market Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.outletName}</span>
                      </Form.Item>
                  </Col> 
              </Row>   
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Store Contact Person Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                          <span>{data.storeContactPersonName}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Store Contact Person No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.storeContactPersonNo}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Weekly Off" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.weeklyOff}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="GST No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.gstNo}</span>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Outlet Onboarding Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{moment(data.outletOnboardingDate).format("YYYY-MM-DD")}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Outlet Closure Date"  style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{moment(data.outletClosureDate).format("YYYY-MM-DD")}</span>

                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Outlet Sales Rep" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Sales  Rep  Start Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{moment(data.salesRepStartDate).format("YYYY-MM-DD")}</span>
                      </Form.Item>
                  </Col> 
              </Row>   
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Sales Rep End Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")}</span>

                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Price List Handover Contact Person Name" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.stockConfirmationContactName}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Price List Hand Over Contact No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.pHandoverContactPersonNo}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="stock Confirmation  Contact Person Name"  style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.pricelistHandoverContactPersonName}</span>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Stock Confirmation Contact Person No" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.stockConfirmationContactNo}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="KYC">
                     <Checkbox
                       checked={data.kyc==="Y"?true:false}
                   
                  >
            
                  </Checkbox>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Authorised Outlet" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                 
                   
                  >
          
                  </Checkbox>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Promotion Allowed" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <Checkbox
                        checked={data.promotionAllowed==="Y"?true:false}
                   
                  >
                 
                  </Checkbox> 
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}>  
                    <Col span={6}>
                      <Form.Item label="Outlet Longitude" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.outletLongitude}</span>
                      </Form.Item>
                    </Col> 
                  <Col span={6}> 
                     <Form.Item label="Outlet Latitude" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                     <span>{data.outletLatitude}</span>
                    </Form.Item> 
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Outlet Pic" >
                       <Image
                       width={100}
                         src={data.outletPic}  />
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Display Done" >
                      <Checkbox
                     checked={data.displayDone==="Y"?true:false}
                   
                  >
                
                  </Checkbox>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}> 
                  <Col span={6}> 
                        <Form.Item label="Trial Period  From  Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                        <span>{moment(data.trialFromPeriod).format("YYYY-MM-DD")}</span>
                        </Form.Item> 
                      </Col>  
                    <Col span={6}>
                      <Form.Item label="Trial Period To Date" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{moment(data.trialToPeriod).format("YYYY-MM-DD")}</span>
                      </Form.Item>
                    </Col> 
                 
                  <Col span={6}>
                      <Form.Item  label="Total Stock" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                       <span>{data.totalStock}</span>
                      </Form.Item>
                  </Col> 
                  <Col span={6}>
                      <Form.Item  label="Projection Jweller Wise Target" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} >
                      <span>{data.projectionJwellerWiseTarget}</span>
                      </Form.Item>
                  </Col> 
              </Row>  
              <p style={{ marginBottom: "20px" }} />
              <Row gutter={16}> 
                  <Col span={6}> 
                        <Form.Item label="Store Wise Target" style={{color:"#080707",fontWeight:'300',fontSize:'13px'}} 
                        >
                          <span>{data.storeWiseTarget}</span>
                        </Form.Item> 
                      </Col> 
              </Row>
                
                  <p/>
                 <hr/>
                 </div>
                                 ))}
              
         </Form>
         
         </Card>
        </Spin>   
       </div>
     )
}

export default CustomWindowHeader