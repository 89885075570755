import React,{useState,useEffect} from 'react'
import {Row,Col,Button,Tabs,Card,Form,Input,Checkbox,Table,message,Image } from 'antd';

import { useHistory } from "react-router-dom"; 
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import {getPjDetailsData,getCustomerInfoTabData,getKycData,getTermsConditionsData,getRegulatoryData,getStockMixData,getOutletData,getDeleteData,getDeleteStockMixData,getDeleteTermsConditionsData,getDeleteOuttLetData,getDeleteKYCData } from "../../../services/custom" 


import "antd/dist/antd.css";
import "../../../styles/antd.css";

const { TabPane } = Tabs;

const PJCustom=()=> { 
  const history = useHistory();

  const [loading, setLoading] = useState(true);  
 
  const  [dataSource, setDataSource] = useState([
  //   { 
  //   businessunit: "stbs",
  //   pjcode:'sdfdf',
  //   pjname:'edc',
  //   customercategory:"bunndiwdjo",
  //   gstno:123,
  //   nickname:"tillu",
  //   pjgroup:'sdsds'
  // }
]);



  const  [recordToDelete, setRecordToDelete] = useState(1);
  const [recordToDeleteInForm,setRecordToDeleteInForm]=useState([])
  const [customerInfoHeder, setcustomerInfoHeder]=useState();
  const [customerInfoTabHeder, setCustomerInfoTabHeder]=useState([]);
  const  [termsAndConditiontab, setTermsAndConditionTab] = useState([])
  const [RegulatoryTabHeader,setRegulatoryTabHeader]=useState([]);
  const [kycTabHeader, setKycTabHeader] = useState([]);
  const  [stockMixHeader, setStockMixHeader] = useState([])
  const  [outletHeader, setOutletHeader] = useState([])

  const [mainForm]=Form.useForm(); 

  const columnsdata = [

    {
      title : 'Business Unit',
      dataIndex: 'bUnitName',
},
  {
      
    title: 'PJ Code',
    dataIndex: 'pjCode',
  }, 
  {
      
    title: 'PJ Name',
    dataIndex:'pjName',
  }, 
  {
      
    title: 'Customer Category',
    dataIndex: 'customerCategory',
  },
  {
      
    title: 'GST No',
    dataIndex: 'gstNo',
  }, 
  {
      
    title: 'Nick Name',
    dataIndex: 'pjName',
  }, 
  {
      
    title: 'PJ Group',
    dataIndex: 'pjGroup',
  },
  {
      
    title: 'Type',
    dataIndex: 'pjtype',
  },
  
  ]
  

  const callBackTabs = (value) => {
      console.log("values",value)
  }
  const saveData = () => {
    mainForm.submit();
    // console.log("save")
  }; 
  const onChangeCheckbox = (e) => {
    const eventId = e.target.id; 
    // console.log("eventId",eventId)
    const checkedValue = e.target.checked; 
  
  }

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  }; 

const getAllFormValuesTab=(event,values)=>{
   console.log("event:",event) 
   console.log("values:",values)
} 

const  onSelectChange = (e) =>{
  setRecordToDelete(e)
  }
 

const rowSelection = {
  onChange: onSelectChange,
}; 

useEffect(() => {
  getData()
  gettabData()
}, [])

const gettabData=async()=>{
  const pjMasterId = localStorage.getItem("pjMasterId"); 
  const kycTabDataresponse = await getKycData(pjMasterId); 
  const termsAndCondition = await getTermsConditionsData(pjMasterId)
  const Regulatoryresponse= await getRegulatoryData(pjMasterId) 
  const StockMixDataresponse=await getStockMixData(pjMasterId)
  const outletHederResponse=await getOutletData(pjMasterId)

  setOutletHeader(outletHederResponse) 
  // console.log("Regulatoryresponse",Regulatoryresponse)
  // console.log("Regulatoryresponse",pjMasterId)

  // console.log("outlethedsrtreturn",outletHeader)
  console.log("outletrututn",outletHederResponse) 

  setStockMixHeader(StockMixDataresponse) 
  console.log("StockMixDataresponse",StockMixDataresponse)
  //  console.log("stock",stockMixHeader)
  // console.log("teamsandconditopn",termsAndCondition)
  // console.log("kyctab",kycTabData) 
  //  console.log("rekyc",kycTabDataresponse)
  // setCustomerInfoTabHeder(termsAndCondition) 
  setTermsAndConditionTab(termsAndCondition)
  console.log("termsAndConditiontab",termsAndConditiontab)
  //  console.log("termsAndCondition",termsAndCondition)
  
  setRegulatoryTabHeader(Regulatoryresponse)
  console.log("Regulatoryresponse",Regulatoryresponse)
  setKycTabHeader(kycTabDataresponse);
   console.log("kyctab",kycTabDataresponse)
  // console.log("RegulatoryTabHeader==>",Regulatoryresponse)

}

const getData = async() =>{
  setLoading(true); 
  const pjMasterId = localStorage.getItem("pjMasterId"); 
    const getDataResponse = await getPjDetailsData();
    const customerInfoTabData =await getCustomerInfoTabData(pjMasterId);   
      console.log("customerInfoTabData",customerInfoTabData)
   
    if( customerInfoTabData !== undefined) {
      if( customerInfoTabData.length>0){ 
        setcustomerInfoHeder(customerInfoTabData)
    // console.log("table",getDataResponse)
   
    
    mainForm.setFieldsValue({
      bUnitName : customerInfoTabData[0].bUnitName,
      pjcode: customerInfoTabData[0].pjCode,
      pjname:customerInfoTabData[0].pjName,
      customercategory:customerInfoTabData[0].customerCategory.name,
      gstno:customerInfoTabData[0].gstNo,
      currency:customerInfoTabData[0].currency.isoCode,
       nickname:customerInfoTabData[0].pjName,
      invoicingname:customerInfoTabData[0].currency.isoCode,
      invoicingaddress:customerInfoTabData[0].invoicingAddress,
      CSAlimit:customerInfoTabData[0].csaLimit,
      ASSLimit:customerInfoTabData[0].asslimit,
      ASSStartDate:moment(customerInfoTabData[0].assStartDate).format("YYYY-MM-DD"),
      ASSEndDate:moment(customerInfoTabData[0].assEndDate).format("YYYY-MM-DD"),
      totalconsignmentStock:customerInfoTabData[0].totalConsignmentStock,
      outrightstock:customerInfoTabData[0].outRightStock,
      TotalStock:customerInfoTabData[0].totalStock,
      paymentterms:customerInfoTabData[0].paymentTerms.name,
      customerInfopjtype:customerInfoTabData[0].pjtype,
      customerinfopjgroup:customerInfoTabData[0].pjGroup,
      customerInfoPJClosureDate:moment(customerInfoTabData[0].pjClosureDate).format("YYYY-MM-DD"),
      customerInfoPJOnboardingDate:moment(customerInfoTabData[0].pjOnboardingDate).format("YYYY-MM-DD"),
      customerInfoownername:customerInfoTabData[0].pjName,
      customerinfocity:customerInfoTabData[0].city,
      customerInfostate:customerInfoTabData[0].region.name,
      customerInfozone:customerInfoTabData[0].zone,
      customerInfoEmail:customerInfoTabData[0].email,
      customerinfomobileno:customerInfoTabData[0].mobileNo,
      customerInfoCountry:customerInfoTabData[0].country.name,
      customerInfopincode:customerInfoTabData[0].pincode,
      customerinfoWebSiteaddress:customerInfoTabData[0].websiteAddress,
      customerInfosolitairejewellery:customerInfoTabData[0].solitaireJewellery,
      customerinfosmalldiamondjewellery:customerInfoTabData[0].smallDiamondJewellery,
      customerInfoGoldJewellery:customerInfoTabData[0].goldJewellery,
      customerInfoluxurylifestyleitems:customerInfoTabData[0].luxuryLifestyle,
      customerInfoOthers:customerInfoTabData[0].others,
      customerInfoRegisteredWithDS:customerInfoTabData[0].registeredWithDs,
      customerInfoUnRegisteredWithDS:customerInfoTabData[0].unregisteredWithDs,

     
    })
  }
}




     if(getDataResponse){
  
 
    for (let index = 0; index < getDataResponse.length; index++) {
      getDataResponse[index].key = getDataResponse[index].pjMasterId;
      getDataResponse[index].customerCategory=getDataResponse[index].customerCategory.name
    }  

    setDataSource([...getDataResponse])
   

    setLoading(false);
  }
 
}


const responsiveSearch = {
  xxl: 6,
  xl: 6,
  lg: 6,
  xs: 24,
  sm: 12,
  md: 6,
};

const deleteUser=()=>{
   if(recordToDelete.length>0){
    message.error("Can not delete more than one user!")
   } 
   setRecordToDelete([])
}  



const deleteRegulatoryForm=async(data)=>{ 
  console.log(data) 
 

  // console.log("oool",data.pjMasterId!==data) 
//   const array=[]
// RegulatoryTabHeader.map((item)=>{
//   if(item.pjMasterId!==data.pjMasterId){
//      array.push(item)
//   }
  
// })
// setRegulatoryTabHeader(array)


// console.log("array",array)
    const deletefun  =await getDeleteData(data)
  console.log("deletefun",deletefun)
    if (deletefun.status === "200") {
    
      message.success(deletefun.message);
      console.log("==",deletefun.message)
      // setRegulatoryTabHeader()
      gettabData()
    } else {
      message.error(deletefun.message); 
      // const Regulatoryresponse= await getRegulatoryData(pjMasterId) 
    }
 
}

const deletestockmixtab=async(data)=>{
  const delectresponse=await getDeleteStockMixData(data) 
  console.log("stockmix",delectresponse)
  if (delectresponse.status === "200") {
    
    message.success(delectresponse.message);
    console.log("==",delectresponse.message)
    // setRegulatoryTabHeader()
    gettabData()
  } else {
    message.error(delectresponse.message); 
    // const Regulatoryresponse= await getRegulatoryData(pjMasterId) 
  }

}
const deleteTeamsConditiondata=async(data)=>{
    const responseteamsCondition= await getDeleteTermsConditionsData(data) 
    console.log("=====teams",responseteamsCondition)
    //  if(responseteamsCondition !==undefined){
    if ( responseteamsCondition.status === "200") {
    
      message.success(responseteamsCondition.message);
      console.log("==", responseteamsCondition.message)
      // setRegulatoryTabHeader()
      gettabData()
    } else {
      message.error(responseteamsCondition.message); 
      // const Regulatoryresponse= await getRegulatoryData(pjMasterId) 
    }
  //  }

}

const deleteOutlet=async(data)=>{ 

const deleteOutletResponse=await getDeleteOuttLetData(data)
console.log("=====outlet",deleteOutletResponse)

if (deleteOutletResponse.status === "200") {

  message.success(deleteOutletResponse.message);
  console.log("==", deleteOutletResponse.message)
  
  gettabData()
} else {
  message.error(deleteOutletResponse.message); 
  
}
}

const deleteKYCTab=async(data)=>{ 

  const deleteKYcResponse=await getDeleteKYCData(data)
  console.log("=====kyc",deleteKYcResponse)
  
  if (deleteKYcResponse.status === "200") {
  
    message.success(deleteKYcResponse.message);
    console.log("==",deleteKYcResponse.message)
    
    gettabData()
  } else {
    message.error(deleteKYcResponse.message); 
    
  }
  }


    return (
        <div>
          <Row>
          <Col span={12}>
            <h2>PJ Custom</h2>
          </Col>
          
         
          <Col span={12}  >
          <span style={{float:'right'}}>
            <Button  onClick={saveData} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}  type="primary" >
              Save
            </Button>
          </span>
            &nbsp;
          </Col>
       </Row>
       <Row>
        <Col span={24}> 
          <Card> 
          <Form  layout="vertical" form={mainForm} name="control-pj" onValuesChange={getAllFormValuesTab} onFinishFailed={onFinishFailed}>
            <Tabs tabPosition='left' defaultActiveKey={"All"} onChange={callBackTabs} >
                <TabPane tab="All" key="All" >   
                <Row>
                <Col className="gutter-row" span={6} />
                <Col {...responsiveSearch} />
                 <Col {...responsiveSearch} />  
                <Col {...responsiveSearch} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                {recordToDelete.length >0 ? <span style={{ float: 'right' }}>
                <Button style={{ height: '30px', marginRight: '8px' }} 
                 onClick={deleteUser} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
              </span> : ''}
                </Col>
                </Row> 
                <div>
                
                <Table  
                      size="small" 
                      columns={columnsdata} 
                      dataSource={dataSource} 
                      rowSelection={rowSelection}
                      pagination={false}
                      loading={{
                      spinning: loading,
                      indicator: <LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />,
                      }}
                      onRow={(row) => ({
                       onClick: () => {
                       localStorage.setItem("pjMasterId", row['pjMasterId']);
                       history.push(`/others/window/PJCustomerDetails`);
                        },
                       })}
                       />    
                  </div>
               
                </TabPane>
               
                <TabPane tab="Customer Info" key="CustomerInfo">
                   <div style={{ padding: "8px" }}>
                   <h3>Customer Info</h3> 
                 
                   <Row gutter={16}>
                        
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="bUnitName" label="Business Unit*" style={{ marginBottom: "8px" }}>
                            <Input  />
                          </Form.Item> 
                        </Col>
                       
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pjcode" label="PJ Code*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="pjname" label="PJ Name*" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customercategory" label="Customer Category" style={{ marginBottom: "8px" }}>
                            <Input/>
                          </Form.Item> 
                        </Col>
                       </Row> 
                       <p style={{ marginBottom: "10px" }} />
                       <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="gstno" label="GST No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="currency" label="Currency" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                       
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="nickname" label="Nick Name" style={{ marginBottom: "8px" }}>
                            <Input/>
                          </Form.Item> 
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicingname" label="Invoicing Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} /> 
                     
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                       <Form.Item name="Invoicing(Y/N)" label="Invoicing (Y/N)" style={{ marginBottom: "8px" }}>
                              <Checkbox
                             checked={customerInfoHeder!==undefined ? customerInfoHeder.isInvoicing==="Y"?true:false :""}

                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                              </Col> 
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="invoicingaddress" label="Invoicing Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="CSAlimit" label="CSA Limit" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSLimit" label="ASS Limit" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        
                       </Row>
                       <p style={{ marginBottom: "10px" }} />
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSStartDate" label="ASS Start Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="ASSEndDate" label="ASS End Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       
                        <Col className="gutter-row" span={6} style={{marginTop:'-18px'}}>
                          <Form.Item name="totalconsignmentStock" label="Total Consignment Stock (TCS)" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="outrightstock" label="Outright Stock (OS)" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} />
                       <Row gutter={16}>
                     
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="TotalStock" label="Total  Stock" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="paymentterms" label="Payment Terms" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} />
                       <h3>More Information</h3>
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfopjtype" label="PJ Type" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfopjgroup" label="PJ Group" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJClosureDate" label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoPJOnboardingDate" label="PJ Onboarding Date" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} /> 
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoownername" label="Owner Name" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfocity" label="City" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfostate" label="State" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfozone" label="Zone" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} /> 
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoEmail" label="Email" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfomobileno" label="Mobile No" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoCountry" label="Country" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} />
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfopincode" label="Pincode" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfoWebSiteaddress" label="WebSite Address" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoCompanyLogo" label="Company Logo" style={{ marginBottom: "8px" }}>
                          <Image
                  width={100}
                  src={customerInfoHeder!== undefined ?customerInfoHeder[0].companyLogo:''}  />
                          </Form.Item>
                      </Col>
                         
                       
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoCountry" label="Owner Pic" style={{ marginBottom: "8px" }}>
                          <Image
                          width={100}
                          src={customerInfoHeder!== undefined ?customerInfoHeder[0].ownerPic:''}  />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} />
                        <h3>PJ Sales Distribution In(%)</h3> 
                        <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfosolitairejewellery" label="Solitaire Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerinfosmalldiamondjewellery" label="Small Diamond Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoGoldJewellery" label="Gold Jewellery" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoluxurylifestyleitems" label="Luxury & Lifestyle Items" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row>
                       <p style={{ marginBottom: "10px" }} /> 
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoOthers" label="Others" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row> 
                       <h3>Other Showroom Details (Mention Market Name)</h3> 
                       <Row gutter={16}>
                       <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoRegisteredWithDS" label="Registered With DS" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                        <Col className="gutter-row" span={6}>
                          <Form.Item name="customerInfoUnRegisteredWithDS" label="Un-Registered With DS" style={{ marginBottom: "8px" }}>
                            <Input />
                          </Form.Item>
                        </Col> 
                       </Row> 
                   </div>
                </TabPane>
                <TabPane tab="KYC" key="KYC">
                <h3>KYC</h3>
                {kycTabHeader.map((data)=>(
                <div style={{ padding: "8px" }}>
                <Row>
                   <Col span={24} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                      <span style={{float:'right'}}>
                   <Button style={{ height: '30px', marginRight: '8px' }} 
                  onClick={()=>{deleteKYCTab(data.dvnCustomerId)}} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
                </span>
                    </Col> 
                    </Row> 
                  
                  <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.pjName} name={data.pjName} label="PJ Name" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.nickName} name={data.nickName} label="Nick Name" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>    
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.pjOnboardingDate).format("YYYY-MM-DD")} name={data.pjOnboardingDate} label="PJ Onboarding Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.pjClosureDate).format("YYYY-MM-DD")} name={data.pjClosureDate} label="PJ Closure Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                       </Row>  
                     <p/>
                      <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.pjAddress} name={data.pjAddress} label="PJ Address" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                            <Col className="gutter-row" span={6}>
                            <Form.Item initialValue={data.city} name={data.city} label="City" style={{ marginBottom: "8px" }}>
                            <Input/>
                            </Form.Item>
                            </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.region.name}  name={data.region.name} label="State" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.zone} name={data.zone} label="Zone" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                      </Row>
                    <p/>
                      <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.country.name} name={data.country.name} label="Country" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.pincode} name={data.pincode} label="PinCode" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>  
                           
                       </Row>  
                       <p/>
                       <h3>Owner Details (Field Group)</h3>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.pincode} name={data.pincode} label="PJ Sales Rep" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={moment(data.pjSalesRepStartDate).format("YYYY-MM-DD")} name={data.pjSalesRepStartDate} label="Sales Rep Start Date	" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.pjSalesRepEndDate).format("YYYY-MM-DD")} name={data.pjSalesRepEndDate} label="Sales Rep End Date	" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.pjName} name={data.pjName} label="Owner Name" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row>  
                       <br/>
                       <Row gutter={16}>
                            
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.mobileNo} name={data.mobileNo} label="Mobile No" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.email} name={data.email} label="Email" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                         
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.birthDate} name={data.birthDate} label="Birth Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.anniversaryDate}  name={data.anniversaryDate} label="Anniversary Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row>  
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.pjAddress} name={data.pjAddress}  label="Address" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6} >
                             <Form.Item name={data.ownerPic} label="Owner Pic" style={{ marginBottom: "8px" }}>
                                <Image src={data.ownerPic}/>
                             </Form.Item> 
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.sor} label="SOR" style={{ marginBottom: "8px" }}>
                              <Checkbox
                                 checked={data.sor==="Y"?true:false}
                             
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.dateofEstablishement} name={data.dateofEstablishement} label="Date of Establishment" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                       </Row>  
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.kyc} label="KYC" style={{ marginBottom: "8px" }}>
                              <Checkbox
                                checked={data.kyc==="Y"?true:false}
                             
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6} >
                             <Form.Item name={data.outletPic} label="Company Logo" style={{ marginBottom: "8px" }}> 
                             <Image width={100}
                             src={data.outletPic}/>
                              </Form.Item>
                          </Col> 
                       </Row>  
                       <br/> 
                       {kycTabHeader.length>1 ? <hr/>:null} 
                      </div>
                      ))}
                </TabPane>

                <TabPane tab="Terms & Conditions" key="Terms & Conditions">
                <h3>Terms & Conditions</h3>
                {termsAndConditiontab.map((data)=>(
                <div style={{ padding: "8px" }}>

                <Row>
                   <Col span={24} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                      <span style={{float:'right'}}>
                   <Button style={{ height: '30px', marginRight: '8px' }} 
                  onClick={()=>{deleteTeamsConditiondata(data.dvnCustomerAddressId)}} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
                </span>
                    </Col> 
                    </Row>
                
                  <Row gutter={16}>
                
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.margin} name={data.margin} label="Margin" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.depositCommited}  name={data.depositCommited} label="Deposit Commited" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item  name="creditLimit" label="Credit Limit" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                           
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.creditPeriod} name={data.creditPeriod} label="Credit Period" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row>  
                       <br/>
                       <Row gutter={16}> 
                            <Col className="gutter-row" span={6} style={{marginTop:'-18px'}}>
                              <Form.Item   initialValue={data.projectionJwellerWiseTarget}  name={data.projectionJwellerWiseTarget} label="Projection Jeweller Wise Target" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>  
                          </Col>   
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.storeWiseTarget} name={data.storeWiseTarget} label="Store wise target" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.storeWisePromptPayment} name={data.storeWisePromptPayment} label="Store Wise Prompt Payment	" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                              
                          </Col>
                     
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.agreementDate).format("YYYY-MM-DD")} name={data.agreementDate} label="Agreement Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col> 
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.agreementSign} label="Agreement Sign" style={{ marginBottom: "8px" }}>
                              <Checkbox
                                checked={data.agreementSign==="Y"?true:false}
                             
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                            </Col> 
                           
                       </Row> 
                       <br/>
                       {termsAndConditiontab.length>1 ? <hr/>:null}
                   </div> 
                      
                   
                   ))}
                 
                </TabPane>
                
                <TabPane tab="Regulatory" key="Regulatory"> 
             
                <h3>Regulatory</h3>
                 {RegulatoryTabHeader.map((data)=>{
                   return (
                    <div style={{ padding: "8px" }}>
                    <Row>
                   <Col span={24} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                      <span style={{float:'right'}}>
                   <Button style={{ height: '30px', marginRight: '8px' }} 
                 onClick={()=>{deleteRegulatoryForm(data.cBankDetailsId)}} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
                </span>
                    </Col> 
                    </Row>
                    <Row gutter={16}>
              
                         <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.bankAccNumber} name={data.bankAccNumber} label="Bank Account No*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>  
  
                          </Col>
                        
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.bankname} name={data.bankname} label="Bank Name*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col> 
                           
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.branch} name={data.branch} label="Branch" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.ifscCode} name={data.ifscCode} label="IFSC Code" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                             
                          </Col>
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.gstNo} name={data.gstNo} label="GST No" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.panNo} name={data.panNo}  label="PAN No" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item name={data.tdsApplicable} label="TDS Applicable" style={{ marginBottom: "8px" }}>
                              <Checkbox
                                checked={data.tdsApplicable==="Y"?true:false}
           
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item> 
                             
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.gstApplicable} label="GST Applicable" style={{ marginBottom: "8px" }}>
                              <Checkbox
                             
                             checked={data.gstApplicable==="Y"?true:false}
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                            
                          </Col> 
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.incentivePromptPayment} label="Incentive Prompt Payment	" style={{ marginBottom: "8px" }}>
                              <Checkbox
                                checked={data.incentivePromptPayment==="Y"?true:false}
                             
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.jwellerlevelPromtPayment} label="Jeweller Level Prompt Payment		" style={{ marginBottom: "8px" }}>
                              <Checkbox
                               checked={data.jwellerlevelPromtPayment==="Y"?true:false}
                             
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}
                            ></Checkbox>
                              </Form.Item> 
                           </Col>
                           
                       </Row> 
                       <br/>
                       {RegulatoryTabHeader.length>1 ? <hr/>:null}
                        
                   </div>  )
                 })}

                </TabPane> 
                <TabPane tab="Stock  Mix" key="Stock  Mix">
                <h3>Stock  Mix</h3>
                {stockMixHeader.map((data)=>(
                <div style={{ padding: "8px" }}> 
                <Row>
                   <Col span={24} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                      <span style={{float:'right'}}>
                   <Button style={{ height: '30px', marginRight: '8px' }} 
                  onClick={()=>{deletestockmixtab(data.dOtherClassificationId)}} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
                </span>
                    </Col> 
                    </Row>
                   
                    <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.small} name="SMSmall" label="Small %*" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                             
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.medium} name="SMMedium" label="Medium %*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.large} name="SMLarge" label="Large %*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.exLarge} name="SMExLarge" label="Ex.Large %*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}>                        
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.defVvs} name={data.defVvs} label="DEF VVS %*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.defVs}  name={data.defVs} label="DEF VS%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.defSi}  name={data.defSi} label="DEF SI%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                           
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.ghVvs}  name={data.defSi} label="GH VVS%*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.ghVs} name={data.ghVs} label="GH VS%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.ghSi} name={data.ghSi} label="GH SI%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                           
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.ijkVvs} name={data.ijkVvs} label="IJK VVS%*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            
                          </Col>
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.ijkVs} name={data.ijkVs} label="IJK VS%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                           
                       </Row> 
                       <br/>
                       <Row gutter={16}>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.ijksi} name={data.ijksi} label="IJK SI%*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.dsd}  name={data.dsd} label="DSD %*" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>                   
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.dsj}  name={data.dsj} label="DSJ %*" style={{ marginBottom: "8px" }}>
                              <Input/>
                              </Form.Item>
                            </Col> 
                           
                       </Row> 
                       <br/>
                       {stockMixHeader.length>1 ? <hr/>:null} 
                      </div>
                      ))}  
                </TabPane>
                <TabPane tab="Outlet" key="Outlet">
                <h3>Outlet</h3> 
                {outletHeader.map((data)=>(
                 
                <div style={{ padding: "8px" }}>
                <Row>
                   <Col span={24} style={{textAlign:'right',marginTop:'0.5%',marginBottom:'0.5%'}}>
                      <span style={{float:'right'}}>
                   <Button style={{ height: '30px', marginRight: '8px' }} 
                  onClick={()=>{deleteOutlet(data.customerContactId)}} 
                >
                  <i
                    style={{ fontWeight: '600' }}
                    className="lnr lnr-trash"
                  />
                </Button>
                </span>
                    </Col> 
                    </Row>
                   
                  <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.outletName} name={data.outletName} label="Outlet Name" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.nickName}  name={data.nickName}  label="Nick  Name" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.addressLine1}  name={data.addressLine1} label="Outlet Address" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.outletCity}  name={data.outletCity} label="City" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.pinCode} name={data.pinCode} label="Pin" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.stateName} name={data.stateName} label="State" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.zone} name={data.zone} label="Zone" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.tier} name={data.tier} label="Tier" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.mobileNo} name={data.mobileNo} label="Phone No" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.area} name={data.area} label="Area" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.email} name={data.email} label="Email" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.outletName} name={data.outletName} label="Market Name" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                          <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.storeContactPersonName} name={data.storeContactPersonName} label="Store Contact Person Name	" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.storeContactPersonNo} name={data.storeContactPersonNo} label="Store Contact Person No	" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.weeklyOff} name={data.weeklyOff} label="Weekly Off" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.gstNo} name={data.gstNo} label="GST No" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.outletOnboardingDate).format("YYYY-MM-DD")} name={data.outletOnboardingDate} label="Outlet Onboarding Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col>  
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={moment(data.outletClosureDate).format("YYYY-MM-DD")} name={data.outletClosureDate} label="Outlet Closure Date " style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.salesRepStartDate).format("YYYY-MM-DD")} name={data.salesRepStartDate} label="Outlet Sales Rep" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.salesRepStartDate).format("YYYY-MM-DD")} name={data.salesRepStartDate} label="Sales  Rep  Start Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.salesRepEndDate).format("YYYY-MM-DD")} name={data.salesRepEndDate} label="Sales Rep End Date" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6} style={{marginTop:'-16px'}}>
                              <Form.Item initialValue={data.pricelistHandoverContactPersonName} name={data.pricelistHandoverContactPersonName} label="Price List Handover Contact Person Name" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>                         
                            <Col className="gutter-row" span={6} style={{marginTop:'-16px'}}>
                              <Form.Item initialValue={data.pHandoverContactPersonNo} name={data.pHandoverContactPersonNo} label="Price List Hand Over Contact No" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6} style={{marginTop:'-16px'}}>
                              <Form.Item  initialValue={data.stockConfirmationContactName} name={data.stockConfirmationContactName} label="Stock Confirmation  Contact Person Name" style={{ marginBottom: "8px" }}>
                                <Input />
                              </Form.Item>
                          </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                          <Col className="gutter-row" span={6} style={{marginTop:'-18px'}}>
                              <Form.Item initialValue={data.stockConfirmationContactNo} name={data.stockConfirmationContactNo} label="Stock Confirmation Contact Person No" style={{ marginBottom: "8px" }}>
                                <Input/>
                              </Form.Item> 
                          </Col>
                            <Col className="gutter-row" span={6}>
                              <Form.Item  name={data.kyc} label="KYC" style={{ marginBottom: "8px" }}>
                               <Checkbox
                                 checked={data.kyc==="Y"?true:false}
                                 
                              style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}>
                               </Checkbox>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item name="OUTLETAuthorisedOutlet" label="Authorised Outlet" style={{ marginBottom: "8px" }}>
                              <Checkbox
                             
                         
                                style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}>
                               </Checkbox>
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6} >
                              <Form.Item name={data.outletPic} label="Outlet Pic" style={{ marginBottom: "8px" }}>
                                <Image width={100} src={data.outletPic}/>
                              </Form.Item>
                          </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                              <Form.Item name={data.displayDone} label="Display Done" style={{ marginBottom: "8px" }}>
                                   <Checkbox
                                 checked={data.displayDone==="Y"?true:false}
                                
                                style={{ marginTop: "8px", color: "#5d5454", fontWeight: "300" }}>
                               </Checkbox>
                              </Form.Item>
                            </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.trialFromPeriod).format("YYYY-MM-DD")} name={data.trialFromPeriod} label="Trial Period  From  Date" style={{ marginBottom: "8px" }}>
                                   <Input/>
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={moment(data.trialToPeriod).format("YYYY-MM-DD")} name={data.trialToPeriod} label="Trial Period To Date" style={{ marginBottom: "8px" }}>
                                 <Input/>
                              </Form.Item> 
                          </Col> 
                            <Col className="gutter-row" span={6}>
                              <Form.Item  initialValue={data.totalStock}  name={data.totalStock} label="Total Stock" style={{ marginBottom: "8px" }}>
                                 <Input/>
                              </Form.Item>
                            </Col> 
                       </Row> 
                       <br/>
                       <Row gutter={16}>
                            <Col className="gutter-row" span={6} style={{marginTop:'-18px'}}>
                              <Form.Item initialValue={data.projectionJwellerWiseTarget} name={data.projectionJwellerWiseTarget} label="Projection Jweller Wise Target" style={{ marginBottom: "8px" }}>
                                   <Input/>
                              </Form.Item>
                          </Col> 
                          <Col className="gutter-row" span={6}>
                              <Form.Item initialValue={data.storeWiseTarget} name={data.storeWiseTarget} label="Store Wise Target" style={{ marginBottom: "8px" }}>
                                 <Input/>
                              </Form.Item> 
                          </Col>
                       </Row>
                       {outletHeader.length>1?<hr/>:null}
                   </div>
                   ))}
                </TabPane>
             </Tabs>

           </Form>   
            </Card>
            </Col>
       </Row>
      
        </div>
    )
}

export default PJCustom


