/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Card,Row,Col,Button,Form,Select,Spin} from 'antd'
import { LoadingOutlined } from "@ant-design/icons";
// import { ExportToCsv } from 'export-to-csv'
// import { v4 as uuid } from 'uuid'
// import moment from 'moment'
// import Axios from 'axios'
import { useGlobalContext } from "../../../lib/storage";
// import { getSupplierData,getSupplierAddress} from "../../../services/generic";
// import { getDeliveryLocation,getSupplierProduct,getLandedCostData,getDraftPoDocs,getDraftpoProducts,getRoleBusinessUnit,getAppSetupData } from "../../../services/custom";
// import barcodeImage from "../../../assets/images/barcode.svg";
// import {serverUrl,genericUrl,fileDownloadUrl} from '../../../constants/serverConfig'
import "antd/dist/antd.css";
import "../../../styles/antd.css";

const {Option} = Select

const InitialDataLoad = (props) => {
const { globalStore } = useGlobalContext();
const Themes = globalStore.userData.CW360_V2_UI;
const [bunitData,setBunitData] = useState([])
const [loading,setLoading] = useState(false)

const [headerform] = Form.useForm()

useEffect(() => {
  
 }, []);


    return (
      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
        <div>
          <Row>
            <Col span={12}>
              <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Initial Data Load</h2>
            </Col>
            <Col span={12}>
              <span style={{ float: "right" }}>
                <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>Process</Button>
              </span>
            </Col>
          </Row>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={headerform} name="control-hooks" /* onFinish={onFinish} */>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="dlType" label="Data Load Type" style={{ marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      // onFocus={getBusinessUnit}
                      // onSelect={onSelectBusinessUnit}
                    >
                      {bunitData.map((data, index) => (
                        <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                          {data.bUnitName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <p />          
        </div>
      </Spin>
    );
}

export default InitialDataLoad