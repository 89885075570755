import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select, Card, Col, Row, Spin, DatePicker, message, Upload, Button ,Table,Checkbox,InputNumber} from "antd";
import { LoadingOutlined, UploadOutlined,EditOutlined,DeleteOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { getProductCategoryAttribute, getDesignDetails,getVarients } from "../../../services/custom";
import "antd/dist/antd.css";
import "../../../styles/antd.css";
import Axios from "axios";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router";

import moment from "moment";
import { serverUrl } from "../../../constants/serverConfig";
import DesignDetails from "../NewProductDesign/designDetails";

import { useParams } from "react-router-dom";
import { getProductCategoryData, getNewProductData, getSubProductData, getDesignedByData, getSketchRefData } from "../../../services/generic";

const NewProductDesign = () => {
  const { Option } = Select;
  const [headerForm] = Form.useForm();
  const [variantForm] = Form.useForm();

  const [form] = Form.useForm();

  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const [designCode, setDesignCode] = useState("");
  const [designName, setDesignName] = useState("");
  // const [designDetails, setDesignDetails] = useState([]);
  const [bUnitId, setBUnitId] = useState("");
  const [productId, setProductId] = useState("");
  const [subProductId, setSubProductId] = useState(null);
  const { recordId } = useParams();
  const [sketchRef, setSketchRef] = useState(null);
  const [designedById, setDesignedById] = useState(null);
  const [designId, setDesignId] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [designDetail, setDesignDetails] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState("");
  const [imageVisible, setImageVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [skuName,setSkuName] = useState("")
  // const [isKey, setIsKey] = useState("");

  const [data,setData] = useState([])	
  const [id, setId] = useState(null);
  const [variantFormData,setVariantFormData] = useState([])
  const [variantImg,setVariantImg] = useState('')
  const [cadValue,setCadValue] = useState("N")
  // const [attrColumns,setAttrColumns] = useState([])	
  // const [variantAttri,setVariantAttri] = useState([])	
  const [variantVisible,setVariantVisible] = useState(false)

  const [attributeVisible,setAttributeVisible] = useState(false)
  const [variantCreation,setVariantCreation] = useState(false)
 
  const [bunitData, setBunitData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [subProductData, setSubProductData] = useState([]);
  const [designedByData, setDesignedByData] = useState([]);
  const [sketchData, setSkecthData] = useState([]);
  const [isValue, setIsValue] = useState(false);
  

  const changePage = () => {
    history.push(`/others/window/7447`);
  };

  useEffect(() => {
    if (recordId === "New_Record") {
      setIsValue(true);
      form.resetFields();
      headerForm.resetFields();
      setFormVisible(false);
      setDesignName("");
      setDesignCode("");
      setData([])
      setIsTableVisible(true);
      setVariantVisible(false)
    } else if (recordId) {
      setIsTableVisible(true);
      setLoading(true);
      getData(recordId);
      setData([])
      setVariantVisible(true)
    } else {
      setIsTableVisible(false);
      // getDesignData("");
    }
  }, [recordId]);
  const history = useHistory();

  // const passData = (data) => {
  //   console.log(data)
  //   if(data.length>0){
  //     getDesignData(data);
  //   }
  // };
  // const variantData =(data)=>{
  //   if(data.length>0){	
  //     setData(data)	
  //   }	
  //   console.log(data)
  // }	

  const editProduct = (record)=>{
    setId(record.plmDesignVarientId)
    setVariantCreation(true)
    console.log(record)	
    let obj = {};
    record?.attributes.forEach((item) => {
        obj[`${item.name}-${item.designAttributeId}`] = item.value;
      });
      record?.metaVariants.forEach((item) => {
        switch (item.key){
        case "approvalDate+": return null; 
        case "skuCreationDate+": return null 
        default : return obj[item.key] = item.value === "undefined" ? null : item.value;
        }
      });
      // obj[item.key] = moment(item.value).format("DD-MM-YYYY")
    console.log(obj)	

    variantForm.setFieldsValue(obj)
    variantForm.setFieldsValue({
      // data:record.data,
      // plmDesignVarientId: record.plmDesignVarientId,
      designImage:record.image,
      createCad:'true',
      description:record.description
    })
  }	
  const deleteRow =(record)=>{	
    let newData = []	
    // console.log(record.plmDesignVarientId)
    setId(record.plmDesignVarientId)
    data.forEach((element)=>{	
      if(element.plmDesignVarientId !== record.plmDesignVarientId){	
        newData.push(element)	
      }	
    })
    setData(newData)
    createVari("Delete",record.plmDesignVarientId)
  }	
  
  const getCadValue =(e)=>{
    if(e.target.checked){
      setCadValue("Y")
    }else{
      setCadValue("N")
    }
  }

  useEffect(() => {
    getVarientsData(recordId);
  }, [recordId])

  const getVarientsData = async (recordId) => {
    let varientsData = await getVarients(recordId);
    let newArry = [];
    varientsData?.map((value) => {
      let attributeData = value.attributes;
      let metaVariantsData = value.metaVariants;

      let obj = {};
      let obj1 = {};
      let obj2 ={}
      attributeData.forEach((item) => {
        obj[item.name] = item.value;
      });
      metaVariantsData.forEach((item) => {
        switch (item.key){
          case "SkuCreationDate+" : {
            return obj2[item.key] = moment(item.value).format("DD-MM-YYYY")
          }
          case "approvalDate+" : {
            return obj1[item.key] = moment(item.value).format("DD-MM-YYYY")
          }
          case "SkuCreatorName+" : {
            return obj2[item.key] = item.value;
          }
          case "Exclusivity+" : {
            return obj2[item.key] = item.value;
          } 
          default : {
            return  obj1[item.key] = item.value;
          }

        }
        // if(item.key === "skuCreationDate+" || item.key === "approvalDate+") {
        //   obj1[item.key] = moment(item.value).format("DD-MM-YYYY")
        // }else{
        //   obj1[item.key] = item.value;
        // }
      });
      value.data = obj
      value.data1 = obj1
      value.data2 = obj2
      newArry.push(value);
    });
if(newArry.length>0){
    setData(newArry)
}
  };

  const createVariant = ()=>{	
    variantForm.resetFields()
    setVariantCreation(true)
  }	

  const createVariantData =()=>{
    const formFieldsData = variantForm.getFieldsValue(true);
    let finalData = [...data]
    let type
    let index = data.findIndex((element) => {
      return element.plmDesignVarientId === id;
    });
    if (index >= 0) {
      type = "Update"
      for(let i=0; i<data.length; i++){
        if(data[i].plmDesignVarientId === id){
          Object.entries(formFieldsData).map((element)=>{
            let id = element[0].split('-')
            switch (id[0]) {
              case "designImage": return data[i].image = element[1] === undefined ? '' :element[1]
              case "createCad": return null
              case "SkuCreationDate+": return data[i].data2[`${id[0]}`] = moment(element[1]).format("DD-MM-YYYY")
              case "ApprovalDate+": return  data[i].data1[`${id[0]}`] = moment(element[1]).format("DD-MM-YYYY")
              case "JwellerySize+": return data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "SkuCreatorName+": return  data[i].data2[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "Exclusivity+": return data[i].data2[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "FindingDetails+": return  data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "ApprovedBy+": return data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "CastingInstructions+": return  data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "RhodiumInstructions+": return  data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "OtherInstructions+": return data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              case "CurrentStatus+": return  data[i].data1[`${id[0]}`] = element[1] === undefined ? '' :element[1]
              default : return data[i].data[`${id[0]}`] = element[1] === undefined ? '' :element[1]
            }
          })
        }
      }
      console.log('data',data)
    }else{
      type = "New"
    const uniqueId = uuid().replace(/-/g, "").toUpperCase();
        const obj = {
          plmDesignVarientId:uniqueId,
          data:{},
          data1:{},
          data2:{}
        }
        Object.entries(formFieldsData).map((element)=>{
          if(element[0] !== 'plmDesignVarientId' && element[0] !== 'data'){
            let id = element[0].split('-')
            switch (id[0]) {
              case "designImage": return obj.image = element[1]
              case "createCad": return null
              case "SkuCreationDate+": return obj.data2[`${id[0]}`] = moment(element[1]).format("DD-MM-YYYY")
              case "ApprovalDate+": return  obj.data1[`${id[0]}`] = moment(element[1]).format("DD-MM-YYYY")
              case "JwellerySize+": return obj.data1[`${id[0]}`] = element[1]
              case "SkuCreatorName+": return  obj.data2[`${id[0]}`] = element[1]
              case "Exclusivity+": return obj.data2[`${id[0]}`] = element[1]
              case "FindingDetails+": return  obj.data1[`${id[0]}`] = element[1]
              case "ApprovedBy+": return obj.data1[`${id[0]}`] = element[1]
              case "CastingInstructions+": return  obj.data1[`${id[0]}`] = element[1]
              case "RhodiumInstructions+": return  obj.data1[`${id[0]}`] = element[1]
              case "OtherInstructions+": return obj.data1[`${id[0]}`] = element[1]
              case "CurrentStatus+": return  obj.data1[`${id[0]}`] = element[1]
              default : return obj.data[`${id[0]}`] = element[1]
            }
            // if(id[0] !== "designImage" && id[0] !== "createCad"){
            //   obj.data[`${id[0]}`] = element[1]
            // }else if(id[0] === "designImage"){
            //   obj.image = element[1]
            // }
          }
        })
        console.log(obj)
        finalData.push(obj)
    }
    createVari(type)
    console.log(finalData)
    setData(finalData)
  }	
  
  const variantModalClose=()=>{	
    setVariantCreation(false)	
    setCadValue("N")
  }

  // service Calls start
  const getBunitId = async () => {
    const productCategory = await getProductCategoryData();
    setBunitData(productCategory.searchData);
  };

  const getProduct = async () => {
    const productResponse = await getNewProductData();
    setProductData(productResponse.searchData);
  };
  const getSubProduct = async () => {
    const subProductResponse = await getSubProductData();
    setSubProductData(subProductResponse.searchData);
  };

  const getDesignedBy = async () => {
    const designedByRes = await getDesignedByData();
    setDesignedByData(designedByRes.searchData);
  };

  const getSkecthRef = async () => {
    const skecthRes = await getSketchRefData();
    setSkecthData(skecthRes.searchData);
  };
  // const getDesignData = async (data) => {
  //   const designDataRes = await getDesign(data,0,100);
  //   designDataRes.forEach((item) => {
  //     item.key = item.plmDesignId;
  //   });
  //   setDesignDetails(designDataRes);
  //   // console.log(designDataRes);
  // };

  const onChange = async (e) => {
    const subProductResponse = await getSubProductData(e);
    setSubProductData(subProductResponse.searchData);
  };
  // service Calls end

  const getData = async (recordId) => {
    const productResponse = await getDesignDetails(recordId);
    setLoading(false);
    console.log(productResponse);
    form.setFieldsValue({
      productCategory: productResponse[0].categoryName,
      designName: productResponse[0].designName,
      designCode: productResponse[0].designcode,
      product: productResponse[0]?.productName,
      subProduct: productResponse[0].subProductName,
      designedBy: productResponse[0].disignerName,
      sketchReference: productResponse[0].sketchName,
      designDescription: productResponse[0].description,
    });
    if (productResponse[0].image === "undefined" || productResponse[0].image === null) {
      setImageVisible(false);
      setImgSrc('')
    }else{
      setImageVisible(true);
      setImgSrc(productResponse[0].image);
    }
    setProductCategoryId(productResponse[0].productCategory);
    setSketchRef(productResponse[0].sketchId);
    setDesignedById(productResponse[0].designedBy);
    setSubProductId(productResponse[0].subProduct);
    setDesignId(productResponse[0].plmDesignId);
    setDesignCode(productResponse[0].designcode);
    setDesignName(productResponse[0].designName);
    setBUnitId(productResponse[0].bunitId);
    setProductId(productResponse[0].product);
    if (productResponse[0].attributes.length > 0) {
      FormValuesData(productResponse[0].attributes);
    }
  };

  const FormValuesData = (FormData) => {
    if (FormData.length > 0) {
      for (let i = 0; i < FormData?.length; i++) {
        if (FormData[i].referenceValue?.includes(",")) {
          FormData[i].data = FormData[i].referenceValue.split(",");
          FormData[i].data1 = FormData[i].value.split(",");
        } else {
          FormData[i].data = [];
          let data = FormData[i].referenceValue ? FormData[i].referenceValue : null;
          FormData[i].data.push(data);
          FormData[i].data1 = [];
          let data1 = FormData[i].value ? FormData[i].value : null;
          FormData[i].data1.push(data1);
        }
      }
      setFormData(FormData);
      // console.log("FormData",FormData)
      setFormVisible(true);
    } else {
      setFormData([]);
      setFormVisible(false);
    }
  };

  const getFormData = async (e) => {
    const FormData = await getProductCategoryAttribute(e);
    FormData.forEach((element) => {
      if (element.value) {
        element.referenceValue = element.value;
        // element.SelectorValue = element.value;
      }
    });
    FormValuesData(FormData);
    console.log('FormData,',FormData)
  };


  const imageUploadStatusChange = (uploadStatus) => {
    const fieldsToUpdate = {};
    fieldsToUpdate["item"] = uploadStatus.file.response;
    console.log(fieldsToUpdate);
    form.setFieldsValue({
      designImage: uploadStatus.file.response,
    });
  };

  const getVariantImage =(uploadStatus)=>{
    const fieldsToUpdate = {};
    fieldsToUpdate["item"] = uploadStatus.file.response;
    console.log(fieldsToUpdate);
    variantForm.setFieldsValue({
      designImage: uploadStatus.file.response,
    });
  }

  // getting form field's id's start
  const getBusUnitId = (e) => {
    // setBUnitId(e);
    setProductCategoryId(e);
  };

  const getProductId = (e) => {
    setProductId(e);
  };

  const getsubProcuctId = (e) => {
    getFormData(e);
    setSubProductId(e);
  };
  const getDesignedById = (e) => {
    setDesignedById(e);
  };
  const getSketchRef = (e) => {
    setSketchRef(e);
  };

  const getDesignCode = (e) => {
    setDesignCode(e.target.value);
  };

  const getDesignName = (e) => {
    setDesignName(e.target.value);
  };

  // getting form field id's end
  const onChangeData =(data)=>{
setDesignDetails(data)
  }

  const onClickNew = ()=>{
    setImageVisible(false)
    setDesignId('')
  }

  // upsert services start
  const createDesignDetails = () => {
    const headerFormValues = headerForm.getFieldsValue(true);
    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue(true);
    console.log(formValues)
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      let uniqueId = uuid().replace(/-/g, "").toUpperCase();
      if (formData.length > 0) {
        setLoading(true);
        const arrayForMutation = [];
        for (let i = 0; i < formData.length; i++) {
    let uniqueId1 = uuid().replace(/-/g, "").toUpperCase();
          Object.entries(headerFormValues).map((item) => {
            if (formData[i].designAttributeId === item[0]) {
              arrayForMutation.push(`{
                designAttributeId : "${formData[i].designAttributeId}",
                lineAttributeId: "${formData[i].lineAttributeId ? formData[i].lineAttributeId : uniqueId1}",
                varient: "${formData[i].varient}",
                value: "${item[1]}"
              }`);
            }
          });
        }
        const create = {
          query: `mutation {
            upsertDesign(design: {
              productCategory:"${productCategoryId}"
               plmDesignId: "${designId ? designId : uniqueId}"
             designcode: "${formValues.designCode}"
             designName: "${formValues.designName}" 
             bunitId: "${bUnitId ? bUnitId : 0}"
             image: "${formValues?.designImage}"
             description: ${JSON.stringify(formValues.designDescription)}
             designedBy: ${designedById ? `"${designedById}"` : null}
             sketchId:  ${sketchRef ? `"${sketchRef}"` : null}
             subProduct: ${subProductId ? `"${subProductId}"` : null}
             product: ${productId ? `"${productId}"` : null}
             attributes: [${arrayForMutation}]
             metaLines:  [{
              key: "Size"
              value: "14"
              plmDesignMetaId: "E21BA56B0B9D45C7B96F203EC7D70084"
         }]
          
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: create,
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${newToken.access_token}`,
          },
        }).then((response) => {
          console.log(response.data.data.upsertDesign.status);
          if (response.data.data.upsertDesign.status === "200") {
            message.success(response.data.data.upsertDesign.message);
            setLoading(false);
            form.resetFields();
            headerForm.resetFields();
            setFormVisible(false);
            setDesignName("");
            setDesignCode("");
            if(designId){
            history.push(`/others/window/7447`)
            }else{
              history.push(`/others/window/7447/${designId ? designId : uniqueId}`)
              window.location.reload();
            }
            setVariantVisible(true)
          } else {
            setLoading(false);
            setImgSrc('')
            message.error("getting error while creating lines");
          }
        });
      } else {
        message.error("select product");
      }
    });
  };

  const createVari =(operation,plmId)=>{
    setLoading(true)
    const variantDetails = variantForm.getFieldsValue()
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    let uniqueId1 = uuid().replace(/-/g, "").toUpperCase();
    const newArry = []
    const metaArry = []
    let img 
    
    Object.entries(variantDetails).map((item)=>{
      if(item[0].indexOf('+') !== -1){
        console.log(item)
      let uniqueId3 = uuid().replace(/-/g, "").toUpperCase();
      metaArry.push(`{
          key: "${item[0]}"
          value: "${item[1]}"
          plmDesignMetaVariantId: "${uniqueId3}"
     }`)
      }})
      let arry = [designCode]
        Object.entries(variantDetails).map((item)=>{
      let uniqueId2 = uuid().replace(/-/g, "").toUpperCase();
          if(item[0].indexOf('+') === -1){
            if(item[0] === 'designImage'){
              setVariantImg(item[1])
              img = item[1]
              console.log(item[1])
            }else if(item[0] !== 'createCad'){
              let id = item[0].split('-')
              formData.map((element)=>{
                if(element.varient === "Y" && element.designAttributeId === id[1]){
                  arry.push(item[1])
                }
              })
              newArry.push(`{
                varientLineId: "${uniqueId2}" 
                mAttributeId: "${id[1]}"
                 value: "${item[1]}"
              }`)
            }
          }
        })
        let arry1 = arry.toString().trim()
        console.log(arry1,arry)
        setSkuName(arry1)
          const createVari = {
             query: `mutation {
              upsertVariant(design: {
                 plmDesignId: "${recordId}"
                image: "${img}"
               description: ""
               designStatus: "${cadValue}"
               plmDesignVarientId: "${plmId? plmId: id ? id : uniqueId1}"
               operations: "${operation}"
               metaVariants:[${metaArry}]
               name:"${arry1}"
               variantAttributes: [${newArry}]
               }) { 
            status
            message   
            }
            }`,
          }
          Axios({
            url: serverUrl,
            method: "POST",
            data: createVari,
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${newToken.access_token}`,
            },
          }).then((response)=>{
            if(response.data.data.upsertVariant.status === "200"){
              message.success(response.data.data.upsertVariant.message);
              setVariantCreation(false)
              setId('')
              variantForm.resetFields()
        setLoading(false)
            }else{
        setLoading(false)
        message.error("getting error while creating variant");
            }
          })
        }
 
    

  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      {isTableVisible ? (
       <div style={{height:'89vh', overflow:'auto'}}>
          <Row>
            <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
              <span
                onClick={changePage}
                style={{
                  fontSize: "11px",
                  paddingLeft: "0px",
                  color: "rgb(22, 72, 170)",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                  fontWeight: "500",
                  fontFamily: "Open sans",
                  cursor: "pointer",
                }}
              >
                Product Design
              </span>

              <span style={{ fontSize: "22px", font: "normal normal 600 22px/30px  Open Sans", color: "#666666" }}>
                {designCode} {designName.length !== 0 ? ` - ${designName}` : ""}
              </span>
            </Col>
            <Col span={12}>
              <span style={{ float: "right" }}>
                <Button onClick={createDesignDetails} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
                  Save
                </Button>
              </span>
            </Col>
          </Row>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={form}>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Product Category"
                    name="productCategory"
                    rules={[
                      {
                        required: true,
                        message: "Please select Product Category!",
                      },
                    ]}
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Select
                      // defaultValue="*"
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getBunitId}
                      onSelect={getBusUnitId}
                    >
                      {bunitData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Product" name="product" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      onSelect={getProductId}
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getProduct}
                    >
                      {productData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Sub Product" name="subProduct" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getSubProduct}
                      onSelect={getsubProcuctId}
                      onSearch={onChange}
                    >
                      {subProductData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <p />
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Design Code"
                    name="designCode"
                    rules={[
                      {
                        required: true,
                        message: "Please select Design Code!",
                      },
                    ]}
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Input onChange={getDesignCode}></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select Design Name!",
                      },
                    ]}
                    label="Design Name"
                    name="designName"
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Input onChange={getDesignName}></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Designed By" name="designedBy" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select
                      onSelect={getDesignedById}
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onFocus={getDesignedBy}
                    >
                      {designedByData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <p />

              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    label="Design Description"
                    name="designDescription"
                    initialValue={description}
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                    ]}
                    style={{ marginBottom: "8px" }}
                  >
                    <ReactQuill
                      theme="snow"
                      // onChange={handleChange}
                      // value={description}
                      modules={{
                        toolbar: [
                          [{ font: [false, "serif", "monospace"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
                          ["bold", "italic", "underline", "strike", "blockquote"],
                          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                          ["code", "background"],
                          ["code-block", "direction"],
                          ["link", "image", "video"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <p />
              
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Sketch Reference" name="sketchReference" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                    <Select onFocus={getSkecthRef} onSelect={getSketchRef}>
                      {sketchData.map((data, index) => (
                        <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  {imageVisible ? (
                    <img style={{ height: 90, width: 90 }} src={imgSrc} />
                  ) : (
                    <Form.Item label="Design Image" name="designImage" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                      <Upload
                        action="https://sapp.mycw.in/image-manager/uploadImage"
                        listType="picture"
                        headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                        name="image"
                        onChange={imageUploadStatusChange}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                  )}
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Customer/Market" name="cstomer/Market" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                  <Input onChange={getDesignCode}></Input>
                  </Form.Item>
                </Col>
              </Row>
              <p/>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Collection"
                    name="collection"
                    style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}
                  >
                    <Input onChange={getDesignCode}></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Targeted Age" name="targetedAge" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                  <Input ></Input>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Gender" name="gender" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                  <Select
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key={1} value={'male'} title={'Male'}>
                          Male
                        </Option>
                        <Option key={2} value={'female'} title={'female'}>
                          Female
                        </Option>
                        <Option key={2} value={'unisex'} title={'unisex'}>
                          Unisex
                        </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <p/>
              <Row gutter={16}>
              <Col className="gutter-row" span={8}>
              <Form.Item name="startDate" label="Start Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item name="estimatedFinisgDate" label="Estimated Finish Date" style={{ color: "#8E8E8E", fontSize: "13px" }}>
                <DatePicker style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} />
              </Form.Item>
            </Col>
                <Col className="gutter-row" span={8}>
                  <Form.Item label="Remarks" name="remarks" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                  <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          {formVisible ? (
            <Card>
              <Form layout="vertical" form={headerForm}>
                <Row gutter={16}>
               
                  {formData.map((element) => {
                    {
                      switch (element.type) {
                        case "ST":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item label={element.name} name={element.designAttributeId} initialValue={element.value}>
                                <Input />
                              </Form.Item>
                            </Col>
                          );
                        case "LI":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item initialValue={element.data1} label={element.name} name={element.designAttributeId}>
                                <Select mode="multiple" maxTagCount="responsive" showSearch style={{ width: "100%", marginBottom: "0px" }}>
                                  {element?.data.map((option, index) => (
                                    <Option key={`${index}-${element.designAttributeId}`} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          );
                        case "DA":
                          return (
                            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                              <Form.Item initialValue={element? moment(element.value) : null} label={element.name} name={element.designAttributeId}>
                                <DatePicker format={"DD-MM-YYYY"} />
                              </Form.Item>
                            </Col>
                          );
                        case "NU":
                          return (
                              <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                                <Form.Item initialValue={element.value} label={element.name} name={element.designAttributeId} >
                                <InputNumber  style={{ width: "100%", marginBottom: "0px" }}/>
                                </Form.Item>
                              </Col>
                            );
                      }
                    }
                  })}
                </Row>
              </Form>
            </Card>
          ) : (
            ""
          )}
           {variantVisible ? <Button onClick={createVariant} style={{margin:'8px 0 0 auto',display:'flex',size:'small',justifyContent:'flex-end',color:'rgb(62, 62, 60)'}}>+ Add</Button>	:''}
          {data.map((product, ind) => (	
            <>	
            <div style={{ marginTop: "8px" }}>	
          <Card >	
            <Row gutter={16}>	
              <Col className="gutter-row" span={3}>	
                <img src={product.image} style={{ height: "75px", width: "75px" }} />	
              </Col>	
              <Col className="gutter-row" span={4}>	
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Name</h1>	
              </Col>	
              <Col className="gutter-row" span={4}>	
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Sku Creator Name</h1>	
              </Col>
              <Col className="gutter-row" span={3}>	
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Sku Created Date</h1>	
              </Col>
              <Col className="gutter-row" span={3}>	
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Exclusivity</h1>	
              </Col>
              <Col className="gutter-row" span={3}>	
                <h1 style={{ color: "#8E8E8E", fontSize: "13px" }}>Create CAD</h1>	
              </Col>
              	
              <Col className="gutter-row" span={3}>	
                <span style={{ float: "right" }}>	
                  <EditOutlined style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} onClick={() => editProduct(product)} />	
                  <DeleteOutlined onClick={() => deleteRow(product)} style={{ paddingRight: "15px", fontSize: "18px", color: "#8E8E8E", cursor: "pointer" }} />	
                </span>	
              </Col>	
            </Row>	
            <div style={{
                marginTop: "-50px",
              }}>	
              <Row gutter={16}>	
                <Col className="gutter-row" span={3}></Col>	
                <Col className="gutter-row" span={4}>	
                  <h1 style={{ fontSize: "13px" }}>{product.name}</h1>	
                </Col>	
                <Col className="gutter-row" span={4}>	
                  <h1 style={{ fontSize: "13px" }}>{product.data2["SkuCreatorName+"] === null ? '' : product.data2["SkuCreatorName+"] }</h1>	
                </Col>	
                <Col className="gutter-row" span={3}>	
                  <h1 style={{ fontSize: "13px" }}>{product.data2["SkuCreationDate+"] === null ? '' : product.data2["SkuCreationDate+"] }</h1>	
                </Col>	
                <Col className="gutter-row" span={3}>	
                  <h1 style={{ fontSize: "13px" }}>{product.data2["Exclusivity+"] === null ? '' : product.data2["Exclusivity+"] }</h1>	
                </Col>	
                <Col className="gutter-row" span={3}>	
                  <h1 style={{ fontSize: "13px" }}>{product.designStatus ? product.designStatus : cadValue}</h1>	
                </Col>	
                <Col className="gutter-row" span={3}></Col>	
              </Row>	
            <Row gutter={16}>	
            {Object.entries(product.data).map(((element,ind)=>{
                return(
                  <Col span={3} style={{marginTop:'15px'}}>	
                    {element[0].split(/(?=[A-Z])/).join(" ")}	
                    <br/>
                    {element[1]}	
                  </Col>
                  )	
            }))}	
              </Row>	
              <Row gutter={16} >	
            {Object.entries(product.data1).map(((element,ind)=>{
                element[0] = element[0].slice(0,-1)
                return(
                  <Col span={3} style={{marginTop:'10px'}}>	
                    {element[0].split(/(?=[A-Z])/).join(" ")}	
                    <br/>
                    {element[1]}	
                  </Col>)	
            }))}	
              </Row>
              
            </div>	

          </Card>	
        </div>	
            </>	
        	
      ))}	
      {variantVisible ? <Modal width="75%" centered visible={variantCreation} closable={true} okText="Save" onOk={createVariantData} onCancel={variantModalClose}>
           <Form name="variantForm" layout="vertical" form={variantForm}>
            <Row gutter={16}>
            <Col className="gutter-row" span={8}  >
                    <Form.Item label="Image" name="designImage" style={{ color: "#8E8E8E", fontSize: "13px", marginBottom: "8px" }}>
                      <Upload
                        action="https://sapp.mycw.in/image-manager/uploadImage"
                        listType="picture"
                        headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                        name="image"
                        onChange={getVariantImage}
                        maxCount={1}
                      >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Form.Item>
                </Col>
              <Col span={16}>
                <Row gutter={16}>
                <Col span={6} style={{marginTop:'15px'}}>
                  <Form.Item name='createCad' initialValue={cadValue}
                  >
                  <Checkbox onChange={getCadValue} value={cadValue}>Create CAD</Checkbox>
                  </Form.Item>
                  </Col>
                  
                <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"SKU Creator Name"} name={'SkuCreatorName+'}>
                    <Select mode="multiple" maxTagCount="responsive" showSearch style={{ width: "100%", marginBottom: "0px" }}>
                       <Option>
                                     
                       </Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"SKU Creation Date"} name={'SkuCreationDate+'}>
                        <DatePicker format={"DD-MM-YYYY"}/>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Exclusivity"} name={'Exclusivity+'}>
                    <Select showSearch style={{ width: "100%", marginBottom: "0px" }}>
                       <Option>
                                     
                       </Option>
                    </Select>
                    </Form.Item>
                </Col>
                {/* <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Finding Details"} name={'FindingDetails+'}>
                        <Input />
                    </Form.Item>
                </Col> 
                 <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Jwellery Size"} name={'JwellerySize+'}>
                        <InputNumber style={{width:"100%"}}/>
                    </Form.Item>
                </Col> 
               <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"No Of Castings"} name={'CastingInstructions+'}>
                        <InputNumber style={{width:"100%"}}/>
                    </Form.Item>
                </Col> 
               <Col className="gutter-row" span={12} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Rhodium Instructions"} name={'RhodiumInstructions+'}>
                        <Input.TextArea />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Other Instructions"} name={'OtherInstructions+'}>
                        <Input.TextArea />
                    </Form.Item>
                </Col> 
               <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Current Status"} name={'CurrentStatus+'}>
                    <Select showSearch style={{ width: "100%", marginBottom: "0px" }}>
                       <Option key={'1'} value={"Portfolio"}>
                       Portfolio       
                       </Option>
                       <Option key={'2'} value={"Non portfolio"}>
                       Non portfolio      
                       </Option>
                       <Option key={'1'} value={"Rejected"}>
                       Rejected       
                       </Option>
                    </Select>
                    </Form.Item>
                </Col> */}
                
                {formData.map((element) => {
      switch (element.type) {
        case "ST":
          return (
            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
              <Form.Item label={element.name} name={`${element.name}-${element.designAttributeId}`} >
                <Input />
              </Form.Item>
            </Col>
          );
        case "LI":
          return (
            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
              <Form.Item  label={element.name} name={`${element.name}-${element.designAttributeId}`} >
                <Select showSearch >
                  {element?.data.map((option, index) => (
                    <Option key={`${index}-${element.designAttributeId}`} value={option} style={{ width: "100%", marginBottom: "0px" }}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          );
        case "DA":
          return (
            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
              <Form.Item label={element.name} name={`${element.name}-${element.designAttributeId}`}>
                <DatePicker format={"DD-MM-YYYY"} />
              </Form.Item>
            </Col>
          );
          case "NU":
               return (
                <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                     <Form.Item  label={element.name} name={`${element.name}-${element.designAttributeId}`} >
                     <InputNumber  style={{ width: "100%", marginBottom: "0px" }}/>
                     </Form.Item>
                 </Col>
          );
                      }
                }
            )}
            <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Approval Date"} name={'ApprovalDate+'}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={6} style={{ marginBottom: "24px" }}>
                    <Form.Item label={"Approved By"} name={'ApprovedBy+'}>
                    <Select showSearch >
                   <Option style={{ width: "100%", marginBottom: "0px" }}>
                     
                    </Option>
                  
                </Select>
                    </Form.Item>
                </Col>
                </Row>
              </Col>
            </Row>
           </Form>
      </Modal> : ''}
        </div>	
      ) : (	
        <DesignDetails designDetail={designDetail}  onChangeData={onChangeData} onClickNew = {onClickNew} />	
      )}
    </Spin>
  );
};
export default NewProductDesign;
